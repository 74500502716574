import constants from './constants'
import FetchMyTrips from '../../repositories/my-trips'
import axios from 'axios'

const fetchMyTrips = new FetchMyTrips()

export const fetchBookingsAction = (filters = {}, silent = false) => async (dispatch) => {
  dispatch({ type: constants.IS_MY_TRIP_LOADING, payload: silent ? 'silent' : true })
  let isCancelled = false
  try {
    const bookings = await fetchMyTrips.fetchBookings(filters, 'fetch-my-bookings')
    dispatch({ type: constants.SET_MY_BOOKINGS, payload: bookings })
  } catch (error) {
    isCancelled = axios.isCancel(error)
    if (!isCancelled) {
      dispatch({ type: constants.ERROR_MY_TRIP, payload: error })
    }
  }
  if (!isCancelled) {
    dispatch({ type: constants.IS_MY_TRIP_LOADING, payload: false })
  }
}

export const fetchBookingsBgAction = (requestComplete = () => null) => async (dispatch) => {
  try {
    const bookings = await fetchMyTrips.fetchBookings({}, 'fetch-my-bookings')
    dispatch({ type: constants.SET_MY_BOOKINGS, payload: bookings })
    requestComplete()
  } catch {
    // do nothing
  }
}

export const setBookingNumberAction = (bookingNumber) => ({
  type: constants.SET_BOOKING_NUMBER,
  payload: bookingNumber,
})
