import React, { memo, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ViewHandler from '../../containers/checkout/viewHandler'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, Container, Divider, Button } from '@material-ui/core'
import { Check as CheckIcon, Email, Phone as PhoneIcon } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import Travelers from '../../containers/checkout/details/travelers'
import BookingRefData from '../../containers/checkout/bookingRefData'
import { useHistory } from 'react-router-dom'
import { setBookingData } from '../../store/checkout/checkoutActions'
import Total from '../../containers/checkout/details/total'
import isIframed from '../../utils/isIframed'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'

const useStyles = makeStyles((theme) => ({
  main: ({ isDesktop }) => ({
    backgroundColor: 'white',
    borderRadius: 12,
    padding: isDesktop ? '35px' : '20px',
  }),
  totalBlock: {
    backgroundColor: 'white',
    borderRadius: 12,
  },
  checkMark: {
    fontSize: '36px',
    marginRight: '5px',
  },
  fullWidth: ({ isDesktop }) => ({
    marginLeft: isDesktop ? '-35px' : '-25px',
    marginRight: isDesktop ? '-35px' : '-25px',
  }),
  mainText: {
    fontSize: '16px',
    color: theme.palette.common.black,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  mainTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: -0.5,
    paddingBottom: 26,
  },
  codes: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fixedHeader: {
    position: 'sticky',
    top: 0,
    borderRadius: 12,
    minHeight: '70px',
    backgroundColor: 'white',
    fontWeight: 600,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
    zIndex: 10,
  },
  headerText: {
    fontSize: '18px',
    color: theme.palette.primary.main,
    '& .inner-text': {
      paddingTop: '5px',
    },
  },
  contactUsBlock: {
    backgroundColor: '#eef8f8',
    borderRadius: 4,
    padding: '25px 0 25px 20px',
  },
  contactRow: {
    display: 'flex',
    alignItems: 'center',
  },
  phoneMark: {
    fontSize: '24px',
    marginRight: '13px',
  },
  accommodationBlock: ({ isDesktop }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& > img': {
      width: isDesktop ? '200px' : '100%',
      height: 'auto',
    },

    '& > .text-block': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: isDesktop ? 'flex-start' : 'center',
      paddingTop: isDesktop ? '0' : '20px',
      paddingLeft: isDesktop ? '30px' : '0',
      fontWeight: 600,
    },
  }),
  accommodationBtn: {
    width: '100%',
    borderRadius: '20px',
    textTransform: 'uppercase',
    marginTop: '27px',
    fontWeight: 'bold',
    padding: '8px 16px',
  },
  goHomeBtn: {
    width: '100%',
    borderRadius: '28px',
    textTransform: 'uppercase',
    marginTop: '32px',
    fontWeight: 'bold',
    padding: '19px 16px',
    lineHeight: 'normal',
    fontSize: 18,
  },
  miniHomeButton: {
    //    width: '100%',
    borderRadius: '20px',
    textTransform: 'uppercase',
    marginLeft: '40px',
    fontWeight: 'bold',
    padding: '8px 16px',
    fontSize: 14,
  },
  fixedDiv: {
    position: 'sticky',
    top: 100,
  },
  noteBlock: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(3),
    borderRadius: '4px',
  },
  noteHead: {
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
  noteText: {
    fontSize: '15px',
  },
}))

const BookingSuccess = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { t, i18n } = useTranslation()
  const classes = useStyles({ isDesktop })
  const { user, isAuthenticated, supportTelephone: agencySupportTelephone } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { skipConfirmationEmail } = useSelector((state) => state.auth)
  const { items, bookingResult, publicContacts } = useSelector(
    (state) => state.checkout
  )
  const supportTelephone = user?.supportTelephone || agencySupportTelephone
  const supportEmail = user?.supportEmail || null // TODO: agency option

  const { isHotelsEnabled } = useEnabledBookingTypesHook()

  let emailTo = bookingResult?.extData?.email || null
  if (!emailTo) {
    emailTo = isAuthenticated ? user?.email : publicContacts?.email
  }

  const isBookingLink = bookingResult?.extData?.isBookingLink || false

  let userEmails = []
  for (let key in bookingResult) {
    ; (bookingResult[key]?.confirmEmails || []).forEach((e) => {
      if (!userEmails.includes(e)) userEmails.push(e)
    })
  }

  const hotelBooked = items.some((itm) => itm.type === 'Hotel')
  const hotelCity =
    items[0].type !== 'Train'
      ? items[0].city
      : items[0].city.map((c) => c.Location)
  const history = useHistory()

  useEffect(() => {
    if (!bookingResult) {
      history.push('/')
    }
  }, [bookingResult, history])

  const clearAndGo = (path) => {
    history.push(path)
    dispatch(setBookingData(null))
  }

  const goHome = () => {
    if (isIframed) {
      history.push('/iframe')
    } else {
      history.push('/')
    }
    dispatch(setBookingData(null))
  }
  const approverName = `${bookingResult?.approver?.firstName} ${bookingResult?.approver?.lastName}`

  return (
    <Box pb={40}>
      <Box className={`${classes.fixedHeader} ${classes.flexCenter}`}>
        <Box className={`${classes.headerText} ${classes.flexCenter}`}>
          <CheckIcon className={classes.checkMark} />
          <span className={'inner-text'}>{t(bookingResult?.isApprove ? 'confirm approving' : 'confirm confirmed')}</span>

          <Button
            onClick={() => goHome()}
            className={classes.miniHomeButton}
            color="primary"
            variant="contained"
            disableElevation
          >
            {t('go home page short')}
          </Button>
        </Box>
      </Box>
      <Container maxWidth="lg" disableGutters>
        <Box mt={isDesktop ? 4 : 1} p={isDesktop ? 0 : 1}>
          <Grid
            container
            spacing={isDesktop ? 6 : 2}
            direction={isDesktop ? 'row' : 'column-reverse'}
          >
            <Grid item xs={12} sm={7}>
              <Box className={classes.main}>
                <Typography className={classes.mainTitle}>
                  {t(bookingResult?.isApprove ? 'booking sent for approval' : 'confirm booking')}
                </Typography>
                <Box mb={'18px'}>
                  <Divider />
                </Box>
                {!skipConfirmationEmail && !bookingResult?.isApprove && (
                  <>
                    <Box className={classes.mainText}>
                      <span>
                        {t('confirm send to')}{' '}
                        <span className={classes.bold}>
                          {userEmails.join(', ')}
                        </span>
                      </span>
                    </Box>
                    <Box my={'18px'}>
                      <Divider />
                    </Box>
                  </>
                )}

                {!!bookingResult?.isApprove && (
                  <>
                    <Box key={'notes-block'} className={classes.mainText}>
                      <Box className={`${classes.noteBlock}`}>
                        <span className={classes.noteHead}>{t('please note')}:</span>
                        <span className={classes.noteText}>{t('sent to approval desc', { name: approverName })}</span>
                      </Box>
                    </Box>
                    <Box my={'18px'}>
                      <Divider />
                    </Box>
                  </>
                )}

                {!!bookingResult?.all?.bookings && (
                  <>
                    <Box mb={'50px'} className={`${classes.mainText}`}>
                      <BookingRefData
                        bookingResult={bookingResult.all.bookings}
                      />
                    </Box>
                    <Box mb={'35px'} className={classes.fullWidth}>
                      <Divider />
                    </Box>
                  </>
                )}
                <Box
                  display={'flex'}
                  mb={'35px'}
                  alignItems={'center'}
                  className={`${classes.contactUsBlock}`}
                  flexDirection="column"
                >
                  <Box className={classes.contactRow}>
                    <span className={`${classes.mainText}`}>
                      {t(bookingResult?.isApprove ? 'if you want to contact support' : 'change booking call')}
                    </span>
                  </Box>

                  <Box className={classes.contactRow} mt={2}>
                    <PhoneIcon className={classes.phoneMark} />
                    {t('telephone')}&nbsp;<span className={classes.bold}>{supportTelephone}
                    </span>
                  </Box>

                  {supportEmail && (
                    <Box className={classes.contactRow} mt={2}>
                      <Email className={classes.phoneMark} />
                      {t('contact email')}&nbsp;<span className={classes.bold}>{supportEmail}</span>
                    </Box>
                  )}

                </Box>
                <Box mb={'35px'} className={classes.fullWidth}>
                  <Divider />
                </Box>
                <Box>
                  <Travelers
                    padding={0}
                    items={items}
                    noBottomBorder
                    bookingResult={bookingResult}
                  />
                </Box>

                {!hotelBooked &&
                  !isBookingLink &&
                  isHotelsEnabled &&
                  hotelCity && (
                    <Box>
                      <Box my={'35px'} className={classes.fullWidth}>
                        <Divider />
                      </Box>
                      <Typography className={classes.mainTitle}>
                        {t('confirm accommodation')}
                      </Typography>
                      <Box mb={'35px'}>
                        <Divider />
                      </Box>
                      <Box className={classes.accommodationBlock}>
                        <img
                          alt={'hotel'}
                          src={'/assets/images/add-accommadation.png'}
                        />
                        <Box className={`text-block ${classes.mainText}`}>
                          {t('hotel in')}{' '}
                          {i18n.language === 'sv' ? hotelCity[1] : hotelCity[0]}
                        </Box>
                      </Box>
                      <Button
                        onClick={() => clearAndGo('/hotels')}
                        className={classes.accommodationBtn}
                        color="primary"
                        variant="contained"
                        disableElevation
                      >
                        {t('confirm btn')}
                      </Button>
                    </Box>
                  )}
              </Box>
              {!isBookingLink && (
                <Button
                  disableElevation
                  onClick={() => goHome()}
                  className={classes.goHomeBtn}
                  color="primary"
                  variant="contained"
                >
                  {t('go home page')}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box className={isDesktop ? classes.fixedDiv : ''}>
                <ViewHandler disableEdit={true} />
                <Box className={classes.totalBlock} mt={2}>
                  <Total isSideBar={true} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default memo(BookingSuccess)
