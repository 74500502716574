import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { fetchSuggestion } from '../../repositories/suggestions'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import AutomateBookingRailTrip from '../../components/automateBooking/AutomateBookingRailTrip'
import AutomateBookingHotelSearch from '../../components/automateBooking/AutomateBookingHotelSearch'
import SelectedTripsData from '../../components/automateBooking/SelectedTripsData'
import moment from 'moment/moment'
import TransferModal from '../../containers/modals/transferModal'
import LanguageChooser from '../../components/reusable/languageChooser'
import SelectFlightFares from "../../components/automateBooking/flightFare/SelectFlightFares"
import FlightIcon from '@material-ui/icons/FlightTakeoff'
import RailIcon from '@material-ui/icons/DirectionsRailway'
import TransferIcon from '@material-ui/icons/LocalTaxi'
import _ from 'lodash'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {fetchTrainCityByCode} from "../../repositories/trains";
import {fetchTransfers, fetchAvailability} from "../../repositories/transfer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9'
  },

  header: {
    display: 'flex',
    position: 'sticky',
    padding: '0 20px',
    top: 0,
    zIndex: '2',
    backgroundColor: 'white',
    height: '65px',
    width: '100%',
    borderBottom: 'solid 1px #ededed',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: "0px 0px 11px -2px rgba(0,0,0,0.50)"
  },

  headerSideBlock: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '60px',
    justifyContent: 'flex-start',

    '&.right': {
      justifyContent: 'flex-end',
    }
  },

  headerCenterBlock: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%'
  },

  backButtonOuter: {
    display: 'inline-flex',
    cursor: 'pointer'
  },

  headerText: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    color: '#333',
    marginLeft: '15px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      marginLeft: '10px',
    },
  },

  headerIcon: {
    height: '25px',
    width: 'auto',
    color: '#0dc5b8',
    marginRight: '7px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '4px',
      height: '20px'
    },
  },

  logoImage: {
    height: '52px',
    width: 'auto',

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  contentRoot: {
    display: 'flex',
    flex: 1,
    padding: '24px 10px 25px 10px',
    flexDirection: 'column',

    alignItems: 'center',
    width:'100%',

    '&.editing-hotel': {
      padding: 0
    },

    '&.white': {
      backgroundColor: 'white',

      '& $container': {
        border: 'none'
      }
    }
  },

  containerFF: {
    [theme.breakpoints.up('slg')]: {
      maxWidth: '1082px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '18px 0'
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    minHeight: '680px',
    padding: '25px 50px',
    borderRadius: '8px',
    border: 'solid 1px #ededed',

    [theme.breakpoints.up('slg')]: {
      maxWidth: '1082px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '18px 0'
    },
  },

  tripsContainer: {
    marginBottom: '135px'
  },

  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    color: theme.palette.secondary.main,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold
  },

  loadingBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1'
  }
}))

const EmailBookingChooseTrip = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    error: false,
    suggestion: null,
    trips: null,
    editingTrip: null,
    tripSelected: false,
    editTransfersData: null,
    bookingState: false,
  })
  let { jwt, act } = useParams()

  const getCurrentTicket = useCallback(async () => {
    setState({...state, isSent: true, loading: true})
    const resultState = {...state, isSent: true, loading: false}
    try {
      if (!['approve', 'choose-trip'].includes(act)) {
        throw new Error('no ticket found')
      }
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const data = await fetchSuggestion(jwt)
      if (!data?.suggestion) throw new Error('no ticket found')
      resultState.suggestion = {...data.suggestion}
      resultState.error = null
      setState(resultState)
      return true
    } catch (e) {
      resultState.error = 'no ticket found'
      resultState.suggestion = null
      setState(resultState)
      return false
    }
  }, [state, jwt, act])

  const selectTrip = async trips => {
    const retDate = moment(state.suggestion?.parsedSourceData?.returnDate, 'YYYY-MM-DD HH:mm')
    const editKey = state.editingTrip

    if (!editKey) {
      return false
    }

    let newState = null;
    if (
        (editKey !== 'hotel' && !!trips?.outbound && (!!trips?.return || !retDate.isValid())) ||
        (editKey === 'hotel' && !!trips?.hotelData && !!trips?.roomData)
    ) {
      setState({...state, loading: true})
      newState = {
        ...state,
        editingTrip: editKey !== 'flight' ? null : editKey,
        editingTripDir: null,
        tripSelected: editKey === 'flight',
        trips: {...(state.trips || {}), [editKey]: {...trips}},
        prevTrip: {...state.trips || {}},
        loading: false
      }
      const transfers = await checkTransfersData(_.cloneDeep(newState), _.cloneDeep(state));
      if (transfers) {
        newState.trips.transfers = transfers;
      }

    } else {
      newState = {
        ...state,
        editingTrip: null,
        editingTripDir: null,
        tripSelected: false,
        loading: false
      }
    }
    setState(newState)
  }

  const selectedHotelCode = useMemo(() => {
    const changed = state?.trips?.hotel?.hotelData?.hotelCode;
    const selected = state?.suggestion?.tripData?.hotel?.hotelCode;
    return changed || selected;
  }, [state])

  const selectFlightServices = useCallback( services => {
    if (state.editingTrip !== 'flight' || !state.trips?.['flight']) {
      return false
    }

    setState({
      ...state,
      editingTrip: null,
      tripSelected: false,
      trips: {
        ...(state.trips || {}),
        flight: {
          ...state.trips.flight,
          ff: services?.ff || null,
          bags: services?.bags || null,
        },
        flightSeats: services?.seats || null
      }
    })
  }, [state])

  const selectFlightSeat = useCallback( seats => {
    setState({
      ...state,
      trips: {
        ...(state.trips || {}),
        flightSeats: seats || null
      }
    })
  }, [state])


  const buildDataForTransfersModal = (newState = null) => {
    const tmpState = newState || state;
    const suggestion = tmpState.suggestion
    const trips = tmpState.trips
    const resTrip = {}
    const flTrip = trips?.flight || suggestion?.tripData?.flight || null
    const railTrip = trips?.rail || suggestion?.tripData?.rail || null
    const transferKeys = []

    if (flTrip) {
      const isEdited = !!trips?.flight
      transferKeys.push('to_flight_outbound');
      transferKeys.push('from_flight_outbound');

      if (!!flTrip?.return) {
        transferKeys.push('to_flight_return');
        transferKeys.push('from_flight_return');
      }

      if (isEdited) {
        resTrip.flight = {
          outbound: flTrip?.outbound?.original?.trip,
          return: flTrip?.return?.original?.returnTrip || null,
        }
      } else {
        resTrip.flight = {
          outbound: flTrip?.outbound?.segments,
          return: flTrip?.return?.segments || null,
        }
      }
    }

    if (railTrip) {
      resTrip.rail = {}

      if (!!railTrip.outbound) {
        transferKeys.push('to_rail_outbound');
        transferKeys.push('from_rail_outbound');

        resTrip.rail.outbound = {
          segments: railTrip.outbound.segments,
          railstart: railTrip.outbound.scheduleSolution.railstart,
          railend: railTrip.outbound.scheduleSolution.railend,
        }
      }

      if (!!railTrip.return) {
        transferKeys.push('to_rail_return');
        transferKeys.push('from_rail_return');

        resTrip.rail.return = {
          segments: railTrip.return.segments,
          railstart: railTrip.return.scheduleSolution.railstart,
          railend: railTrip.return.scheduleSolution.railend,
        }
      }
    }
    const transfers = trips?.transfers || suggestion?.tripData?.transfers || null
    const resTransfers = []
    transferKeys.forEach(tk => {
      if (!transfers?.[tk]?.length) return false;
      const tmpTr = transfers[tk]
      tmpTr.forEach(trData => {
        if (!trData || typeof trData !== 'object') {
          return false;
        }
        const foundU = (suggestion?.passengers || []).find(p => {
          return !!p.uind && p.uind === (trData.userInd || null)
        })

        if (!foundU) {
          return false;
        }

        const isMultiPass = ['FBUS', 'RAILE'].includes(trData.product.code);
        resTransfers.push({
          ...trData,
          passenger: isMultiPass ? [foundU.uniqueId] : foundU.uniqueId,
          isPending: true
        })

      })
    })

    if (!resTrip) return false

    return {
      trip: resTrip,
      transfers: resTransfers
    }
  }

  const getFlightMeta = tripData => {
    try {
      const { outbound, return: inbound } = tripData?.trip?.flight || {}
      if (!outbound?.length) return {}

      const getFlNum = (trip) => {
        if (!trip) return null
        return `${trip.company || trip.airline || trip.operatingCarrier}${
          trip.flightnumber
        }`
      }


      const results = {}
      const oFirst = outbound[0]
      const oLast = outbound.slice(-1)[0]

      const rFirst = inbound?.[0] || null
      const rLast = inbound?.length ? inbound.slice(-1)[0] : null;
      const isInt = oFirst?.fromCountryCode && oFirst?.fromCountryCode !== oLast?.fromCountryCode;

      const oConn = outbound
        ? {
          departureCode: oFirst.from,
          departureDate: new moment(oFirst.departure).format('YYYY-MM-DDTHH:mm:ss'),
          arrivalCode: oLast.to,
          arrivalDate: new moment(oLast.arrival).format('YYYY-MM-DDTHH:mm:ss'),
          international: isInt,
          type: 'FLIGHT',
        } : null

      const rConn = inbound?.length
        ? {
          departureCode: rFirst.from,
          departureDate: new moment(rFirst.departure).format('YYYY-MM-DDTHH:mm:ss'),
          arrivalCode: rLast.to,
          arrivalDate: new moment(rLast.arrival).format('YYYY-MM-DDTHH:mm:ss'),
          international: isInt,
          type: 'FLIGHT',
        } : null


      if (outbound) {
        results.to_flight_outbound = {...oConn, number: getFlNum(oFirst)}
        results.from_flight_outbound = {...oConn, number: getFlNum(oLast)}
      }

      if (inbound) {
        results.to_flight_return = {...rConn, number: getFlNum(rFirst)}
        results.from_flight_return = {...rConn, number: getFlNum(rLast)}
      }

      return results
    } catch (e) {
      return {}
    }
  }

  const getRailMeta = async tripData => {
    try {
      const {
        outbound,
        return: inbound,
      } = tripData?.trip?.rail || {}

      if (!outbound?.railstart) return {};
      const fromCode = await fetchTrainCityByCode(outbound?.railstart?.raillocationCode)
      const toCode = await fetchTrainCityByCode(outbound?.railend?.raillocationCode)
      if (!fromCode?.UIC || !toCode?.UIC) return {};
      const results = {}

      const oConn = outbound
        ? {
          departureCode: fromCode.UIC,
          departureDate: outbound.railstart.dateTime,
          arrivalCode: toCode.UIC,
          arrivalDate: outbound.railend.dateTime,
          number: outbound.flightnumber,
          international: false,
          type: 'TRAIN',
        } : null

      const rConn = inbound
        ? {
          departureCode: toCode.UIC,
          departureDate: inbound.railstart.dateTime,
          arrivalCode: fromCode.UIC,
          arrivalDate: inbound.railend.dateTime,
          number: inbound.flightnumber,
          international: false,
          type: 'TRAIN',
        } : null

      if (!!outbound) {
        results.to_rail_outbound = {...oConn, number: outbound.segments[0].railidentifier}
        results.from_rail_outbound = {...oConn, number: outbound.segments.slice(-1)[0].railidentifier}
      }

      if (inbound) {
        results.to_rail_return = {...rConn, number: inbound.segments[0].railidentifier}
        results.from_rail_return = {...rConn, number: inbound.segments.slice(-1)[0].railidentifier}
      }

      return results;
    } catch (e) {
      return {}
    }
  }

  const getNewTransfer = async (targetMeta, t, key) => {
    try {
      const product = t.product.code;
      const keySplit = key.split('_');
      const locKeys = {to: 'startNode', from: 'endNode'};
      const currLoc = t?.[locKeys?.[keySplit[0]]]?.street;
      if (!currLoc || !product) throw new Error('failed');
      const notTaxi = ['FBUS', 'RAILE'].includes(product);

      if (notTaxi) {
        let params = null;
        if (keySplit[0] === 'from') {
          params = {
            startLocationCode: targetMeta.arrivalCode,
            connectionDate: targetMeta.arrivalDate,
            international: targetMeta.international,
          }
        } else {
          params = {
            endLocationCode: targetMeta.departureCode,
            connectionDate: targetMeta.departureDate,
            international: targetMeta.international,
          }
        }

        const locCheckKeys = ['type', 'code', 'name', 'community'];
        const currLocCheck = locCheckKeys.map(k => currLoc?.location[k] || 'none').join('--')

        const results = await fetchTransfers(params);
        if (!results?.data?.length) throw new Error('failed');
        let found = null;
        let foundGrade = 0;
        results.data.forEach(rt =>  {
          const newLoc = t?.[locKeys?.[keySplit[0]]]?.street?.location;
          if (!newLoc) throw new Error('failed');
          const newLocCheck = locCheckKeys.map(k => newLoc[k] || 'none').join('--')
          if (rt.product.code === product && newLocCheck === currLocCheck) {
            found = rt;
            foundGrade = 3;
          } else if (rt.product.code === product && foundGrade < 2) {
            found = rt;
            foundGrade = 2;
          } else if (!found) {
            found = rt;
            foundGrade = 1;
          }
        })

        if (found) {
          return {
            ...found,
            dirKey: key,
            connection: targetMeta,
            userInd: t.userInd,
            type: 'taxi'
          }
        }
        throw new Error('failed');
      } else {
        let params = null;
        if (keySplit[0] === 'from') {
          params = {
            startLocationCode: targetMeta.arrivalCode,
            connectionDate: targetMeta.arrivalDate,
            endLocationCode: currLoc?.location?.code,
            endStreetName: currLoc?.name,
            endStreetNumber: currLoc?.number,
            type: targetMeta.type,
            connectionArrival: 1,
            international: targetMeta.international,
          }
          if (!!currLoc?.letter) params.endStreetLetter = currLoc.letter
        } else {
          params = {
            startLocationCode: currLoc.location.code,
            endLocationCode: targetMeta.departureCode,
            connectionDate: targetMeta.departureDate,
            startStreetName: currLoc.name,
            startStreetNumber: currLoc.number,
            type: targetMeta.type,
            international: targetMeta.international,
          }
          if (!!currLoc?.letter) params.startStreetLetter = currLoc.letter
        }

        const resTaxi = await fetchAvailability(params);

        if (!resTaxi?.data?.length) throw new Error('failed');
        const foundTaxi = resTaxi?.data.find(rt => rt.product.code === product )
        if (!foundTaxi) throw new Error('failed');

        return {
          ...foundTaxi,
          dirKey: key,
          connection: targetMeta,
          userInd: t.userInd,
          type: 'taxi'
        }
      }
    } catch (e) {
      const res = _.cloneDeep(t)
      if (typeof (res.passenger) !== 'undefined') {
        delete res.passenger
      }
      if (typeof (res.isPending) !== 'undefined') {
        delete res.isPending
      }
      return res;
    }
  }
  const checkTransfersData = async (newState, currState) => {
    try {
      const transfers = state?.trips?.transfers || state?.suggestion?.tripData?.transfers || null
      if (!transfers) return false;
      const currTrData = buildDataForTransfersModal(currState);
      const newTrData = buildDataForTransfersModal(newState);

      const currRailMeta = await getRailMeta(currTrData);
      const newRailMeta = await getRailMeta(newTrData);
      const currFlightMeta = getFlightMeta(currTrData);
      const newFlightMeta = getFlightMeta(newTrData);

      const currMeta = {...(currFlightMeta || {}), ...(currRailMeta || {})};
      const newMeta = {...(newFlightMeta || {}), ...(newRailMeta || {})};
      const keysForCheck = [
        'departureCode', 'departureDate', 'arrivalCode',
        'arrivalDate', 'number', 'international', 'type'
      ];

      const keysForSearch = []
      for (let key in currMeta) {
        const tmpConn = currMeta[key];
        const connKey = keysForCheck.map(k => tmpConn?.[k] || 'none').join('--');
        const tmpNewConn = newMeta[key];
        const newConnKey = keysForCheck.map(k => tmpNewConn?.[k] || 'none').join('--');
        if (connKey !== newConnKey && transfers?.[key]) keysForSearch.push(key);
      }
      const resTransfers = {}
      for (let tKey in transfers) {
        if (!transfers[tKey]?.length) return;
        const targetMeta = newMeta?.[tKey];
        for (let trInd in transfers[tKey]) {
          const t = transfers[tKey][trInd];
          if (!resTransfers[tKey]) resTransfers[tKey] = [];

          if (targetMeta && keysForSearch.includes(tKey)) {
            const res = await getNewTransfer(targetMeta, t, tKey)
            resTransfers[tKey].push(res);
          } else {
            const res = _.cloneDeep(t);
            if (typeof (res.passenger) !== 'undefined') {
              delete res.passenger;
            }
            if (typeof (res.isPending) !== 'undefined') {
              delete res.isPending;
            }
            resTransfers[tKey].push(res);
          }
        }
      }
      if (!Object.values(resTransfers).length) return null;
      return resTransfers;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  const skipEditing = useCallback(() => {
    setState({...state, editingTrip: null, editTransfersData: null})
  }, [state])

  const editTransfers = useCallback((res) => {
    const tr = res.details || [];
    let results = null

    let error = false
    tr.forEach(newT => {
      if (!newT?.transfer?.length || !newT?.meta?.index || !newT?.meta?.connection) return false;
      if (!results) results = {}
      results[newT.meta.index] = [];
      [...newT.transfer].forEach(t => {
        const usersToCheck = [];
        if (typeof t.passenger === 'string') {
          usersToCheck.push(t.passenger)
        } else if(Array.isArray(t.passenger) && t.passenger.length) {
          usersToCheck.push(...t.passenger)
        } else {
          error = 'invalid transfer user';
          return false
        }

        usersToCheck.forEach(uInd => {
          const foundU = (state.suggestion?.passengers || []).find(p => {
            return !!p.uniqueId && p.uniqueId === uInd
          })
          if (!foundU) {
            error = 'invalid transfer user';
            return false;
          }

          results[newT.meta.index].push({
            ...t,
            userInd: foundU?.uind,
            connection: newT.meta.connection
          });
        })

      })
    })

    const newTrips = {...(state.trips || {})}
    if (!error) {
      if (!!results) newTrips.transfers = {...results}
    }
    setState({...state, trips: newTrips, editingTrip: null, editTransfersData: null})
  }, [state])


  const onEdit = (type, dir) => {
    if (type === 'transfer') {
      const transferData = buildDataForTransfersModal()
      if (!transferData) return false;
      setState({...state, editingTrip: type, editingTripDir: null, editTransfersData: transferData})
      return true
    }
    setState({...state, editingTrip: type, editingTripDir: dir, editTransfersData: null})
  }

  const isError = !state.loading && !!state.error
  const isEditing = state.editingTrip;
  const tripSelected = !isError && !state.loading && !!state.tripSelected
  const isTripsList = (
    !isError &&
    !state.loading &&
    !!isEditing &&
    !tripSelected &&
    !!state.suggestion &&
    ['rail', 'flight'].includes(isEditing)
  )
  const isFFSelect = !isError && !state.loading && !!tripSelected && state.editingTrip === "flight"
  const isHotelEditing = isEditing === 'hotel' && !!state?.suggestion && !state.loading && !isError

  const goBack = () => {
    const newState = {...state, tripSelected: false}
    if (isFFSelect) {
      const editKey = state.editingTrip
      const prev = (!!state?.prevTrip?.[editKey] && typeof state.prevTrip[editKey] === 'object') ? {...state.prevTrip[editKey]} : null
      if (!!newState?.trips?.[editKey]) newState.trips = {...newState.trips, [editKey]: prev || undefined};
    } else if (isTripsList || isHotelEditing) {
      newState.editingTrip = null;
    } else { return false }
    setState(newState)
  }

  useEffect(() => {
    if (!state.isSent) getCurrentTicket()
  }, [state, getCurrentTicket])

  const renderLoading = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <Box key="loading-block" className={classes.loadingBlock}>
        <CircularProgress/>
      </Box>
    </Container>
  ), [classes])

  const renderError = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <Box className={classes.errorBlock}>
        {t('suggestion not found')}
      </Box>
    </Container>
  ), [classes])

  const renderTrips = () => (
    <Container disableGutters className={`${classes.container} ${classes.tripsContainer}`}>
      <AutomateBookingRailTrip
        selectTrip={selectTrip}
        suggestion={state.suggestion}
        direction={state.editingTripDir || 'outbound'}
        jwt={jwt}
        tripType={state.editingTrip}
      />
    </Container>
  )

  const renderFFSelect = useCallback( () => (
    <Container disableGutters className={classes.containerFF}>
      <SelectFlightFares
        trip={{outboundTrip: state.trips.flight.outbound, returnTrip: state.trips.flight.return}}
        ticketData={state.suggestion}
        passengers={state.suggestion.passengers}
        token={jwt}
        cancel={() => {}}
        select={selectFlightServices}
      />
    </Container>
  ), [state, jwt, selectFlightServices, classes])

  const renderTripsData = useCallback( () => (
    <SelectedTripsData
      suggestion={state.suggestion}
      selectFlightSeat={selectFlightSeat}
      trips={state.trips}
      jwt={jwt}
      onEdit={onEdit}
      setBookingState={(bState) => setState({...state, bookingState: bState})}
    />
  ), [state, classes, jwt])

  const getTripTo = () => {
    const defaultTxt = state.suggestion?.parsedSourceData?.destLocation || state.suggestion?.parsedSourceData?.hotelCity || ''
    const langMatch = (i18n.language || '').match(/(\w{2})-(\w{2})/i)
    const lang = langMatch?.[1] || i18n.language
    const translations = state?.suggestion?.cityTranslations
    const translated = translations?.[lang] || translations?.['en'] || defaultTxt;
    return translated.replace(/\w+/g, _.capitalize);
  }

  const getHeaderIcons = () => {
    const icons = []
    if (!!state.suggestion?.tripData?.flight) {
      icons.push(<FlightIcon key={'header-flight-icon'} className={classes.headerIcon}/>)
    }

    if (!!state.suggestion?.tripData?.rail) {
      icons.push(<RailIcon key={'header-rail-icon'} className={classes.headerIcon}/>)
    }

    if (!!state.suggestion?.tripData?.transfers) {
      icons.push(<TransferIcon key={'header-transfer-icon'} className={classes.headerIcon}/>)
    }

    return icons
  }
  return (
    <Box key={'root'} className={classes.root}>
      <Box key={'header'} className={classes.header}>
        <Box key={"header-left-block"} className={classes.headerSideBlock}>
          {(!!isFFSelect || !!isTripsList || !!isHotelEditing) && (
            <Box key="back-arrow-outer" className={classes.backButtonOuter} onClick={goBack}>
              <ArrowBackIcon color="primary" />
            </Box>
          )}
        </Box>
          <Box key={'header-center-block'} className={classes.headerCenterBlock}>
            {!state.loading && !state.bookingState && (
              <Fragment>
                {getHeaderIcons()}
                <span className={classes.headerText}>
                  {t('trip to')} {getTripTo()}
                </span>
              </Fragment>
            )}
          </Box>
        <Box key={"header-right-block"} justifyContent={'flex-end'} className={classes.headerSideBlock}>
          <LanguageChooser/>
        </Box>
      </Box>
      <Box key={'contentRoot'} className={`${classes.contentRoot} ${!!isEditing && `editing-${isEditing}`} ${!!isHotelEditing || !isEditing ? 'white' : ''}`}>
        {!!state.loading && renderLoading()}
        {!!isError && renderError()}
        {!!isTripsList && renderTrips()}
        {(!isEditing || isEditing === 'transfer') && !state.loading && !isError && renderTripsData()}
        {isHotelEditing && (
          <AutomateBookingHotelSearch suggestion={state.suggestion} jwt={jwt} onSelect={selectTrip} selectedHotel={selectedHotelCode}/>
        )}
        {!!isFFSelect && renderFFSelect()}
      </Box>
      {!!state.editTransfersData && (
        <TransferModal
          trip={state.editTransfersData.trip}
          addedTransfers={state.editTransfersData.transfers}
          modalBoolean={!!state.editTransfersData && state.editingTrip === 'transfer'}
          onClose={skipEditing}
          skipLocalStorage={true}
          users={state.suggestion.passengers}
          onSelect={editTransfers}
          taxiOnly={true}
        />
      )}
    </Box>
  )
}

export default EmailBookingChooseTrip
