import React, { useReducer, useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import { loginAction, setLogInError } from '../../store/auth/authAction'
import { getUserStatus } from '../../repositories/auth'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import constants from '../../store/auth/constants'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import storage from 'local-storage-fallback'
import { closeAllModals } from '../../store/modals/modalAction'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const fieldBorderStyles = {
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  border: '1px solid',
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    borderRadius: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    lineHeight: '29px',
    padding: theme.spacing(2, 3),
    letterSpacing: 1.1,
    maxWidth: '227px',
    width: '100%',
    height: '55px',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  alignBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnText: {
    marginLeft: '10px',
    textTransform: 'none',
  },
  coloredLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    cursor: 'pointer',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '6px',
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: '6px',
    },
    '& .MuiInputBase-formControl': {
      borderRadius: '6px',
    },
    '& .Mui-error': {
      fontSize: '14px',
    },
  },

  checkInfoBlock: {
    backgroundColor: theme.palette.dimWhite,
    padding: '13px 19px',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    lineHeight: '1.64',
    borderRadius: '4px',
    marginBottom: '31px',
  },

  agencyNotice: {
    padding: '16px',
    borderRadius: '6px',
    backgroundColor: '#fdf7e5',
  },

  noActivationNotice: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '16px',
    textAlign: 'center',
  },

  ediLink: {
    position: 'absolute',
    top: '18px',
    right: '20px',
    cursor: 'pointer',
    zIndex: 1,
  },

  borderRadius: {
    marginTop: 0,
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    '&:before, & .MuiInputBase-root:before': { display: 'none' },
    '& fieldset': { ...fieldBorderStyles },
    '& .MuiInputBase-input.Mui-disabled': {
      padding: '18.5px 14px',
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#333',
      borderRadius: '6px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {},
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '&.Mui-disabled .MuiSelect-icon': {
      color: '#333',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },

  regular: {
    fontWeight: theme.typography.fontWeightRegular,
  },

  orText: {
    marginTop: '10px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

function reducer(state, action) {
  switch (action.type) {
    case 'set_company':
      return { ...state, companyId: action.payload }
    case 'set_agency':
      storage.setItem('url', `https://${action.payload}/api`)
      return { ...state, agency: action.payload }
    case 'set_email':
      return { ...state, email: action.payload }
    case 'set_password':
      return { ...state, password: action.payload }
    case 'set_activationNotAllowed':
      return { ...state, activationNotAllowed: action.payload }
    case 'set_touched':
      return {
        ...state,
        touched: action.payload,
      }
    default:
      throw new Error()
  }
}

const AccountLogin = ({ activationSent, emailNotFound, onStatusChanged }) => {
  let lastLogged = storage.getItem('user')
  try {
    lastLogged =
      typeof lastLogged == 'string' && lastLogged.trim() !== ''
        ? JSON.parse(lastLogged)
        : null
  } catch (e) {
    lastLogged = null
  }
  const passRef = useRef(null)
  const initStatus =
    lastLogged?.userStatus && typeof lastLogged.userStatus === 'object'
      ? lastLogged.userStatus
      : null
  const initialState = {
    email: lastLogged?.userEmail || '',
    password: '',
    touched: false,
    companyId: null,
    activationNotAllowed: false,
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showPassword, setShowPassword] = React.useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [userStatus, setUserStatus] = useState(initStatus)
  const [statusLoading, setStatusLoading] = useState(false)
  const needStatusCheck = useMemo(
    () => !userStatus?.exists || !userStatus?.activated,
    [userStatus]
  )
  const classes = useStyles()
  const { t } = useTranslation()
  const reduxDispatch = useDispatch()
  const { isLogginIn, logInError, choiceOfAgencies, allowRegistration } =
    useSelector((state) => state.auth)
  const history = useHistory()

  const onSubmit = () => {
    if (statusLoading || isLogginIn) return
    if (needStatusCheck) {
      checkUserStatus()
    } else {
      startLogin()
    }
  }

  const checkUserStatus = async () => {
    setStatusLoading(true)
    let tmpStatus = null
    //    await new Promise((resolve) => setTimeout(resolve, 1200)) //fake request timeout
    try {
      tmpStatus = await getUserStatus({
        email: (state.email || '').trim().toLowerCase(),
        agency: state.agency,
        sendActivation: true,
      })
    } catch (error) {
      if (error.response?.status === 418) {
        reduxDispatch({
          type: constants.SET_CHOICE_OF_AGENCIES,
          payload: error.response.data,
        })
      } else {
        tmpStatus = { exists: false, activated: false }
      }
    }

    // SSO-enabled customer - send to IODC login flow
    if (tmpStatus.isSSOEnabled) {
      return history.push(`/oidc/${tmpStatus.companyId}`)
    }

    // Registration-enabled customers - send to registration flow
    if (tmpStatus.isRegistrationCompanyAvailable) {
      return history.push(`/register`, { email: state.email })
    }

    if (tmpStatus?.exists && !tmpStatus?.activated && tmpStatus?.emailSent) {
      setTimeout(() => activationSent({ email: state.email }), 200)
    } else if (tmpStatus && !tmpStatus?.exists) {
      setTimeout(
        () =>
          emailNotFound({
            email: state.email,
            ...(tmpStatus?.agencyData || {}),
          }),
        200
      )
    } else if (
      tmpStatus?.exists &&
      !tmpStatus?.activated &&
      !tmpStatus?.emailSent
    ) {
      // Self-activation is not allowed
      console.log('not allowed')
      dispatch({ type: 'set_activationNotAllowed', payload: true })
    }
    setUserStatus(tmpStatus)
    setStatusLoading(false)
    onStatusChanged(tmpStatus)
  }

  const startLogin = (companyId = null) => {
    const payload = {
      email: (state.email || '').trim().toLowerCase(),
      password: state.password,
      agency: state.agency,
      selectedCompanyId: companyId,
    }
    return reduxDispatch(loginAction(payload, userStatus))
  }

  const onChange = (event) => {
    reduxDispatch(setLogInError(''))
    dispatch({ type: 'set_touched', payload: true })
    const { name, value } = event.target
    dispatch({ type: `set_${name}`, payload: value })
  }

  const onRegister = () => {
    reduxDispatch(closeAllModals())
    history.push('/register')
  }

  const agencyMenuItems = choiceOfAgencies?.map((agency) => (
    <MenuItem key={agency.domain} value={agency.domain}>
      {agency.title}
    </MenuItem>
  ))

  const renderAgency = (value) => {
    if (value) return value
    else return t('choose agency placeholder')
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  if (state.activationNotAllowed) {
    return (
      <Box className={classes.root} pl={isMobile ? 0 : 2} pr={isMobile ? 0 : 2}>
        <Box mb={2} className={classes.noActivationNotice}>
          {t('activation not allowed')}
        </Box>
      </Box>
    )
  }

  const handleForgotPassword = () => {
    reduxDispatch(closeAllModals())
    history.push('/forgot-password')
  }

  return (
    <Box className={classes.root} pl={isMobile ? 0 : 2} pr={isMobile ? 0 : 2}>
      {choiceOfAgencies && (
        <Box mb={2} className={classes.agencyNotice}>
          <Typography className={classes.medium}>
            {t('choose agency text')}
          </Typography>
          <Box mt={1}>
            <Select
              className={classes.borderRadius}
              fullWidth
              name="agency"
              displayEmpty={true}
              renderValue={renderAgency}
              onChange={onChange}
            >
              {agencyMenuItems}
            </Select>
          </Box>
        </Box>
      )}

      <Box mb={1}>
        <Typography className={classes.medium}>
          {t(needStatusCheck ? 'enter your email' : 'placeholder email')}
        </Typography>
        <Box position={'relative'} mt={1}>
          {!needStatusCheck && (
            <span
              onClick={() => setUserStatus(null)}
              className={`${classes.ediLink} ${classes.coloredLink}`}
            >
              {t('edit btn')}
            </span>
          )}
          <TextField
            name="email"
            fullWidth
            InputLabelProps={{ shrink: false, inputMode: 'email' }}
            label={state.email ? '' : t('placeholder email')}
            variant="outlined"
            value={state.email}
            onChange={onChange}
            className={classes.input}
            type="email"
            InputProps={{ style: { fontWeight: 500 } }}
            inputMode="email"
            inputProps={{
              tabIndex: '1',
              readonly: !needStatusCheck ? '1' : false,
              ...(!needStatusCheck ? { style: { cursor: 'default' } } : {}),
            }}
          />
        </Box>
      </Box>
      {!needStatusCheck ? (
        <Box mt={'13px'}>
          <Box className={classes.alignBetween}>
            <Typography className={classes.medium}>
              {t('placeholder pass')}
            </Typography>

            <Typography
              className={classes.coloredLink}
              tabIndex="4"
              onClick={handleForgotPassword}
            >
              {t('forgot password')}
            </Typography>
          </Box>
          <Box mt={1}>
            <FormControl error={logInError} style={{ width: '100%' }}>
              <OutlinedInput
                error={logInError}
                helperText={logInError ? t('wrong password') : ''}
                name="password"
                placeholder={t('placeholder pass')}
                autoFocus={true}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                inputRef={passRef}
                variant="outlined"
                value={state.password}
                className={classes.input}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={onChange}
                inputProps={{
                  tabIndex: '2',
                  autoComplete: 'off',
                  spellCheck: 'false',
                  autoCorrect: 'off',
                }}
              />
              {!!logInError && (
                <FormHelperText>{t('wrong password')}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      ) : (
        <Box className={classes.checkInfoBlock}>{t('onboarding note')}</Box>
      )}
      <Box mt={5} display="flex" alignItems="center" justifyContent="center">
        <Button
          disableElevation
          onClick={onSubmit}
          color="primary"
          variant="contained"
          className={classes.btn}
          tabIndex="3"
        >
          {isLogginIn || statusLoading ? (
            <CircularProgress size={30} color={'light'} />
          ) : (
            t(needStatusCheck ? 'get started' : 'login')
          )}
        </Button>
      </Box>

      {allowRegistration && (
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <div className={classes.orText} onClick={onRegister}>
            {t('or register account')}
          </div>
        </Box>
      )}
    </Box>
  )
}

export default AccountLogin
