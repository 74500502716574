import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid, Button, Typography, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import WifiIcon from '@material-ui/icons/Wifi'
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast'
import CheckIcon from '@material-ui/icons/Check'
import StarsRating from '../../../components/reusable/ratingsStars'
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getFreeCancellationLabel } from '../../../utils/hotelHelpers'
import { PriorityHigh } from '@material-ui/icons'
import usePriceFormat from '../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
      '&:first-child': { marginLeft: 0 },
    },
  },
  adressText: {
    fontSize: '13px',
  },
  choosebtn: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 4),
    letterSpacing: 1.1,
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    marginTop: theme.spacing(1),
    borderRadius: '6px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  cardYellow: {
    backgroundColor: theme.palette.yellow,
    color: theme.palette.orange,
    marginTop: theme.spacing(1),
    borderRadius: '6px',
    '&:first-child': {
      marginTop: 0,
    },
  },
}))

const HotelInfoMobile = ({ info, addToCheckout, stars, room, searchParams }) => {
  const { t } = useTranslation()
  const hotel = searchParams ? info : useSelector((state) => state.hotels.single);
  const { currency } = useSelector((state) => state.auth)
  const classes = useStyles()
  const urlParams = useParams()
  const { roomsCount } = searchParams || urlParams || {}
  const renderAdress = info?.CachedData?.ADRESS_LINE_1
  const { freeWifi } = info
  const { formatPrice } = usePriceFormat()

  const {
    breakfastIncluded,
    freeCancellationDeadline,
    cancellationType,
    noWindowRoom,
    isDisabled
  } = room

  const freeCancellationLabel = getFreeCancellationLabel(
    cancellationType,
    freeCancellationDeadline,
    true
  )

  return (
    <Box>
      <Grid container spacing={2} direction="column">
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Typography className={classes.weight} variant="h4">
              {capitalizeFirstLetter(info?.title || '')}
            </Typography>
          </Grid>
          <Grid item>
            <Card elevation={0}>
              <Box p={1}>
                <StarsRating stars={stars} />
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs>
            <Box display="flex" alignItems="center" width="100%">
              <LocationOnIcon fontSize="small" />
              <Typography className={classes.adressText}>
                {capitalizeFirstLetter(renderAdress || '')},{' '}
                {info?.CachedData?.ZIP_CODE},{' '}
                {capitalizeFirstLetter(info?.CachedData?.CITY2 || '')},{' '}
                {capitalizeFirstLetter(info?.CachedData.COUNTRY_NAME || '')}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item>
            {!!noWindowRoom && (
              <Card className={classes.cardYellow} elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <PriorityHigh />
                  <Typography className={classes.align}>
                    {t('room without window')}
                  </Typography>
                </Box>
              </Card>
            )}

            {freeCancellationLabel && (
              <Card className={classes.card} elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <CheckIcon />
                  <Typography className={classes.align}>
                    {freeCancellationLabel}
                  </Typography>
                </Box>
              </Card>
            )}

            {breakfastIncluded && breakfastIncluded !== '0' && (
              <Card className={classes.card} elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <FreeBreakfastIcon />
                  <Typography>{t('hotel popular breakfast')}</Typography>
                </Box>
              </Card>
            )}

            {freeWifi && (
              <Card className={classes.card} elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <WifiIcon />
                  <Typography>{t('wifi included')}</Typography>
                </Box>
              </Card>
            )}
          </Grid>
          <Grid item xs>
            <Typography align="right" variant="h5" className={classes.weight}>
              {hotel &&
                formatPrice(hotel && Math.floor(hotel?.rooms[0].price), {
                  prependCurrency: true,
                })}
            </Typography>
            <Typography align="right">
              {roomsCount} {t('room')}, {roomsCount} {t('night')}{' '}
            </Typography>
            <Typography align="right">
              TOT{' '}
              {hotel &&
                formatPrice(hotel?.rooms[0].totalPrice, {
                  prependCurrency: true,
                })}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            disableElevation
            onClick={addToCheckout}
            className={classes.choosebtn}
            color="primary"
            variant="contained"
            disabled={isDisabled}
          >
            {t('select the room')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(HotelInfoMobile)
