import agent from './agent'

class FetchMyTrips {
  constructor() {
    this.agent = agent
  }

  fetchBookings = async (filters = {}, ctKey = null) => {
    const url = '/v2/get-bookings'

    const { data } = await this.agent.post(
      url,
      {
        searchString: filters?.search,
        users: filters?.users,
        pnr: filters?.pnr,
      },
      { cityCityCT: ctKey }
    )
    return data
  }

  fetchCancelBooking = async ({ pnr, type }) => {
    let url = ''
    const payload = { pnrId: pnr }
    switch (type) {
      case 'rails':
        url = '/rail/refund'
        break
      case 'flight':
        url = '/flights/refund'
        break
      case 'hotel':
        url = '/hotel/refund'
        break
      default:
        console.log('URL', url)
    }

    try {
      const { data } = await this.agent.post(url, payload)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  getByUsersAndDate = async (filters) => {
    const {
      limit = 25,
      page,
      dateFrom,
      dateTo,
      usersID = null,
      groupByUID = false,
      withUpcomingBookings = false,
      withBookings = false,
      userName,
      withInactive = false,
      authType,
    } = filters
    const rqFilters = {
      limit,
      userName,
      page,
      dateFrom,
      dateTo,
      usersID,
      groupByUID,
      withUpcomingBookings,
      withBookings,
      withInactive,
      authType,
    }
    const { data } = await agent.post('/v2/get-bookings-calendar', rqFilters)
    return data
  }

  getExternalTrip = async (pnrId, ctKey = null) => {
    const { data } = await agent.get(`/v2/external-booking/${pnrId}`, {
      cityCityCT: ctKey,
    })
    return data
  }

  cancelPnr = async (pnrId, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel', { pnrId, supportEmail })
    return data
  }

  cancelRail = async (pnrId, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel-rail', { pnrId, supportEmail })
    return data
  }

  /**
   * Cancels a hotel booking.
   *
   * @param {string} pnrId - The PNR ID of the booking.
   * @param {number|null} hotelIndex - The index of the hotel in the PNR. Null to cancel all hotels in PNR.
   * @return {Promise} A promise that resolves to the response data.
   */
  cancelHotel = async (pnrId, hotelIndex, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel-hotel', {
      pnrId,
      hotelIndex,
      supportEmail
    })
    return data
  }

  cancelFlight = async (pnrId, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel-flight', { pnrId, supportEmail })
    return data
  }

  cancelCar = async (pnrId, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel-car', { pnrId, supportEmail })
    return data
  }

  cancelPassenger = async (pnrId, users, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel-pnr-passenger', {
      pnrId,
      users,
      supportEmail
    })
    return data
  }

  cancelSingleTransfer = async (pnrId, transferRef, supportEmail = null) => {
    const { data } = await agent.post('/v2/booking/cancel-transfer', {
      pnrId,
      transferRef,
      supportEmail
    })
    return data
  }

  addTransfersToPnr = async (rqData, pnr, ctKey = null) => {
    try {
      const { data } = await agent.post(
        `/v2/booking/add-transfer-to-pnr`,
        { pnr, data: rqData },
        { cityCityCT: ctKey }
      )
      return data
    } catch (e) {
      return null
    }
  }

  /**
   * Will request a resend of the confirmation email for a PNR.
   * @param {*} pnrId
   * @returns
   */
  requestResendConfirmationEmail = async (pnrId) => {
    const { data } = await agent.post(`/v2/booking/send-confirmation/${pnrId}`)
    return data
  }
}

export default FetchMyTrips
