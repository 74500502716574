import React, { memo } from 'react'
import { Hidden } from '@material-ui/core'
import ExpandedSearchBar from '../../containers/search/rentalCarExpandedSearch'
import MobileSearchBar from '../../containers/search/rentalCarMobileSearch'
import MobileBottomNavigation from '../../containers/footer/mobileBottomNavigation'
import CenterSearchbar from '../../hooks/centerSearchbar'
import BannerRow from '../../containers/header/bannerRow'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'

const Cars = ({ setSearchMode, currentSearchMode, showSearchModes }) => {
  const { isCarsEnabled } = useEnabledBookingTypesHook()

  if (!isCarsEnabled) return null

  return (
    <>
      <BannerRow bannerKey="car"></BannerRow>
      <CenterSearchbar>
        <Hidden mdUp>
          <MobileSearchBar />
        </Hidden>
        <Hidden smDown>
          <ExpandedSearchBar
            setSearchMode={setSearchMode}
            currentSearchMode={currentSearchMode}
            showSearchModes={showSearchModes}
          />
        </Hidden>
      </CenterSearchbar>

      <Hidden smUp>
        <MobileBottomNavigation />
      </Hidden>
    </>
  )
}

export default memo(Cars)
