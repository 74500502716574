export const carLocationIsValid = (location) => {
  if (location?.coords?.lat && location?.coords?.lng) return true
  if (location.place_id) return true
}

export const applyCarFilters = (
  cars,
  filters,
  sort = null,
  carVendorOrder = null
) => {
  let res = (cars || []).filter((c) => {
    const {
      locationKey,
      minPrice,
      maxPrice,
      companies,
      transmissions,
      mileages,
      fuel,
    } = filters || {}

    const fuelMapping = {
      green: ['hybrid', 'electric', 'ethanol'],
      alternative: ['hybrid', 'electric', 'gas', 'hydrogen', 'ethanol'],
      petrol: ['diesel', 'petrol'],
    }

    let fuelFilter = []
    if (!!fuel?.length) {
      fuel.forEach((f) => {
        if (!!fuelMapping?.[f]?.length) {
          fuelFilter = [...fuelFilter, ...fuelMapping[f]]
        }
      })
    }

    const mileage =
      c?.vehicle?.freeMileage === 'UNL' ? 'unlimited' : c?.vehicle?.freeMileage
    const carRransmission = c?.vehicle?.transmission === 'A' ? 'auto' : 'manual'

    // filter cars by location
    if (
      !!locationKey &&
      c?.pickLocation?.uniqueKey !== locationKey &&
      c?.dropLocation?.uniqueKey !== locationKey
    ) {
      return false
    }

    // filter by max price
    if (maxPrice > 0 && c?.estimatedTotal > maxPrice) return false

    // filter by min price
    if (minPrice > 0 && c?.estimatedTotal < minPrice) return false

    // filter by companies
    if (
      companies?.length > 0 &&
      !companies.includes(c?.carCompanyData?.companyName)
    )
      return false

    // filter by transmission
    if (transmissions?.length > 0 && !transmissions.includes(carRransmission))
      return false

    // filter by unlimited mileage
    if (
      mileages?.length > 0 &&
      mileages.includes('unlimited') &&
      mileage !== 'unlimited'
    )
      return false

    // filter by fuel
    if (fuelFilter?.length > 0 && !fuelFilter.includes(c?.vehicle.fuel))
      return false

    return true
  })
  const sortVariants = ['cheapest', 'closest', 'car-company', 'corporate-fare']

  if (!!sort || sortVariants.includes('closest')) {
    res.sort((a, b) => {
      if (sort === 'closest') {
        const aDist = !!a?.pickLocation?.distance
          ? parseFloat(a?.pickLocation?.distance)
          : 0
        const bDist = !!b?.pickLocation?.distance
          ? parseFloat(b?.pickLocation?.distance)
          : 0

        if (aDist < bDist) return -1
        if (aDist > bDist) return 1
        return 0
      } else if (sort === 'cheapest') {
        const aDist = a?.estimatedTotal || 0
        const bDist = b?.estimatedTotal || 0

        if (aDist < bDist) return -1
        if (aDist > bDist) return 1
        return 0
      } else if (sort === 'car-company' && carVendorOrder) {
        const aWeight =
          (carVendorOrder.findIndex(
            (v) => v === a?.carCompanyData.companyCode
          ) ?? 100) *
            10000 +
          a?.estimatedTotal
        const bWeight =
          (carVendorOrder.findIndex(
            (v) => v === b?.carCompanyData.companyCode
          ) ?? 100) *
            10000 +
          b?.estimatedTotal
        if (aWeight < bWeight) return -1
        if (aWeight > bWeight) return 1
        return 0
      } else if (sort === 'corporate-fare') {
        const aCorp = a?.isCorporateFare ? 0 : 1
        const bCorp = b?.isCorporateFare ? 0 : 1
        const aPrice = a?.estimatedTotal || 0
        const bPrice = b?.estimatedTotal || 0

        return (aCorp === bCorp) ? aPrice - bPrice : aCorp - bCorp
      }

      return 0
    })
  }

  return res
}

export const constructOpenHoursStrings = (hours, t) => {
  let days = [...Array(7).keys()].map((day) => t('day' + ' ' + day))

  let currentOpen = hours[0].open
  let currentClose = hours[0].close
  let currentDays = []

  let result = []

  for (let i = 0; i < hours.length; i++) {
    if (hours[i].open === currentOpen && hours[i].close === currentClose) {
      currentDays.push(days[i])
    } else {
      let str =
        (currentDays.length > 1
          ? currentDays[0] + '-' + currentDays.pop()
          : currentDays[0]) +
        ' ' +
        currentOpen +
        '-' +
        currentClose
      result.push(str)

      currentOpen = hours[i].open
      currentClose = hours[i].close
      currentDays = [days[i]]
    }
  }

  return result
}
