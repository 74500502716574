import { showSnackbarAction } from '../../store/snackbars/snackbarAction'
import { fetchBookingsBgAction } from '../../store/myTrips/myTripsAction'
import { useDispatch, useSelector } from 'react-redux'
import { getApiUrl } from '../../repositories/agent'
import { useIsMounted } from '../../utils/hooks.ts'
import { useEffect, useRef, useState } from 'react'
import { ReduxState } from '../../types'

type SyncOptions = {
  skipMe?: boolean
}

// parent component is being wrongly rendered twice with full unmounting
// this causes PNR sync to be called twice
// this is a workaround to prevent the second call
let tsPNRSyncStarted = new Date('2000-01-01')

export const usePNRSync = (bookings: any[], options?: SyncOptions) => {
  const [bookingsScanStatus, setBookingsScanStatus] = useState({})
  const [listReceived, setListReceived] = useState(false)
  const dispatch = useDispatch()
  const isMountedRef = useIsMounted()
  const fetchingBookingsPromise = useRef<Promise<void> | undefined>(undefined)
  const skipMe = Boolean(options?.skipMe)

  const accessToken = useSelector<ReduxState, string>((state) => state.auth.jwtToken)

  const progress = (() => {
    const numBookings = Object.keys(bookingsScanStatus).length
    const numScanned = Object.values(bookingsScanStatus).filter((status) => status === 'synced').length

    return 0.05 + (listReceived ? 0.1 : 0) + (numBookings > 0 ? (numScanned / numBookings) * 0.85 : (listReceived ? 0.85 : 0))
  })();

  useEffect(() => {
    // see my comments at tsPNRSyncStarted variable init
    if (((new Date()).getTime() - tsPNRSyncStarted.getTime() < 1000) || !process.env.REACT_APP_PNR_SYNC || skipMe) return

    tsPNRSyncStarted = new Date()

    const existingPnrs = bookings.map((b) => b.pnr)
    const upcomingPNRs = bookings.filter((b) => b.isUpcoming).map((b) => b.pnr)
    const newBookingsScanStatus = Object.fromEntries(existingPnrs.map((pnr) => [pnr, 'synced']))

    const sse = new EventSource(
      getApiUrl('/v2/get-bookings-live')
      + '?t=' + accessToken
      + (process.env.REACT_APP_PNR_SYNC_FULL ? `&fullSync=true` : '')
      + `&extraPNRs=${upcomingPNRs.join(',')}`
    )

    sse.onmessage = (event) => {
      if (!isMountedRef.current) {
        // it should be safe to stop it completely
        sse.close()
        return
      }

      try {
        const data = JSON.parse(event.data)
        // console.log('SSE incoming message', data)

        switch (data.cmd) {
          case 'PNR_List':
            for (const pnr of data.pnrs) {
              newBookingsScanStatus[pnr] = existingPnrs.includes(pnr) ? 'sync-existing' : 'sync-new'
            }
            setBookingsScanStatus(newBookingsScanStatus)
            setListReceived(true)
            break

          case 'PNR_Retrieve':
            setBookingsScanStatus((prev) => ({
              ...prev,
              [data.pnr]: 'synced',
            }))
            break

          // DB synced -- re-fetch bookings from it
          case 'synced_chunk':
            // TODO: analyze for race conditions
            (async () => {
              if (fetchingBookingsPromise.current) {
                await fetchingBookingsPromise.current
                fetchingBookingsPromise.current = undefined
              }

              fetchingBookingsPromise.current = new Promise((resolve) => {
                void dispatch(fetchBookingsBgAction(resolve))
              })
            })();
            break

          case 'error':
            // just reset them for now
            setBookingsScanStatus(Object.fromEntries(existingPnrs.map((pnr) => [pnr, 'synced'])))
            dispatch(showSnackbarAction('error', 'PNR sync failed', { x: 'right', y: 'top' }))
        }
      } catch {
        sse.close()
      }
    }
    sse.onerror = () => {
      sse.close()
    }
  }, [accessToken, skipMe])

  return {
    progress,
    bookingsScanStatus,
  }
}
