import React, { memo } from 'react'
import _ from 'lodash'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Seat from '../../../icons/Seat'
import OccupiedSeat from '../../../icons/occupiedSeat'
import CustomTooltip from '../../../reusable/tooltip'
import { useSelector } from 'react-redux'
import usePriceFormat from '../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  seat: {
    cursor: ({exists}) => exists ? 'pointer' : 'default',
    minWidth: '23px',
    minHeight: '24px'
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const CellSeat = ({ col, selectSeat, flightKey, passengerSeats, passengers }) => {
  const theme = useTheme()
  const { exists, busy, name, y, userKey } = col
  const { formatPrice } = usePriceFormat()
  const classes = useStyles(col)

  const isSelected = passengerSeats.find(
    (x) => x.seat === col.seat && flightKey === x.flightKey
  )
  const usrInd = isSelected
    ? _.findIndex(passengers, { uniqueId: isSelected.userId })
    : -1

  let seat = busy ? (
    <OccupiedSeat />
  ) : isSelected ? (
    <Seat
      userIndex={usrInd}
      userKey={userKey}
      color={theme.palette.primary.main}
    />
  ) : (
    <Seat />
  )

  const onSeatClick = () => {
    if (exists) {
      selectSeat({ seat: col, flightKey })
    }
  }

  let justText = false
  // Rows in middle
  if (name && !exists) {
    seat = ''
  } else if (!name) {
    justText = true
    seat = <Typography className={classes.weight}>{y}</Typography>
  }
  //let classItem = name || busy ? '' : 'zero'

  const mainBlock = (
    <Box
      onClick={onSeatClick}
      className={!justText ? classes.seat : ''}
    >
      {seat}
    </Box>
  )

  if (!exists) {
    return mainBlock
  }

  return (
    <CustomTooltip
      title={exists ? `${col.seat} ${formatPrice(col.price, { appendCurrency: true })}` : null}
      placement="top"
    >
      {mainBlock}
    </CustomTooltip>
  )
}

export default memo(CellSeat)
