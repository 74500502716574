import React, {
  createContext,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CheckoutFetch from '../../repositories/checkout.js'
import prepareBooking, { getFops } from '../../utils/booking'
import {
  getPublicUsersValidationSchema,
  getPublicUsersFormikInitials,
  getIsFlightRequireDocs,
} from '../../utils/general'
import { getCCFee } from '../../utils/booking/logic/flight'
import { getDelivException } from '../../utils/booking/logic/train'
import {
  getCostfieldsAction,
  setItemsPassengerField,
  getHotelGuaranteeAction,
  getPaymentData,
  setBookingData,
  setCCFees,
  setPublicContacts,
  setDlk,
} from '../../store/checkout/checkoutActions'
import CheckoutBlock from '../../containers/checkout/checkoutBlock'
import ViewHandler from '../../containers/checkout/viewHandler'
import BookingProcessModal from '../../containers/modals/bookingProcessModal'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import { logOutUser } from '../../store/auth/authAction'
import { useTranslation } from 'react-i18next'
import {
  closeAllModals,
  toggleBookingProcess,
} from '../../store/modals/modalAction'
import cityCityException from '../../utils/cityCityException.Class'
import { Formik } from 'formik'
import * as Yup from 'yup'
import valid from 'card-validator'
import BookingFailModal from '../../containers/modals/bookingFailModal'
import isIframed from '../../utils/isIframed.js'
import i18n from 'i18next'
import moment from 'moment'
import storage from 'local-storage-fallback'
import { disableExtendCheckout } from '../../store/search/searchAction'
import {
  getVirtualCustomer,
  getVirtualCustomerConfig,
} from '../../utils/virtualCustomerConfigs.js'
import Cookies from 'js-cookie'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  backBtn: {
    textTransform: 'none',
    fontWeight: 500,
    left: 0,
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    padding: '16px 20px',
    borderRadius: theme.spacing(10),
  },
  forwardBtn: {
    padding: '16px 60px',
    borderRadius: theme.spacing(10),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
  },
  btnContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  justifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },

  fixedDiv: {
    position: 'sticky',
    top: 127,
  },
}))

export const GuaranteeContext = createContext([{}, () => { }])

// fetch costfields in this component - add to user state
const Checkout = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const {
    isAuthenticated,
    user,
    countryPhone,
    showAddressDetails,
    showBirthDate,
    authType,
  } = useSelector((state) => state.auth)
  const needApprove = user?.approveBookings === 'all';
  const checkoutRef = useRef(null)
  const formikRef = useRef()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const store = useSelector((state) => state)
  const {
    costFieldValues,
    costfields,
    items,
    bookingResult,
    guaranteeLoading,
    fop,
    sum,
    creditCard,
    hotelGuarantee,
  } = store.checkout
  const { bookingProcess } = store.modal
  const isFlightRequireDocs = getIsFlightRequireDocs(items)
  const { passengerData } = useSelector((state) => state.checkout)
  const { countriesList } = useSelector((state) => state.other)

  const useExternalPayment = !isAuthenticated || user?.forcePaiwise

  useEffect(() => {
    dispatch(disableExtendCheckout())
    dispatch(closeAllModals())
  }, [dispatch])

  // component did mount
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!items?.length) history.push('/')
  }, [items])
  const fops = useMemo(
    () =>
      getFops({
        isAuthenticated: isAuthenticated || false,
        checkoutState: store.checkout,
      }),
    [store.checkout, isAuthenticated]
  )
  const [guarantee, setGuarantee] = useState(false)
  const [error, setError] = useState(false)
  const hideHotelGuarantee = guarantee && hotelGuarantee
  const isCcNeeded = fops.required.includes('cc') || fop === 'cc'
  const checkoutItem = useMemo(
    () => items.find((itm) => itm.type === 'Train' || itm.type === 'Flight'),
    [items]
  )

  const car = useMemo(() => {
    let resCar = null
    if (items && typeof items === 'object') {
      resCar = items.find((itm) => itm.type === 'Car')
    }
    return resCar || null
  }, [items])

  const hotel = useMemo(() => {
    let resHotel = {}
    if (items && typeof items === 'object') {
      resHotel = items.find((itm) => itm.type === 'Hotel')
    }
    return resHotel || {}
  }, [items])

  const mainTripUsers = !!checkoutItem
    ? checkoutItem.passengers || []
    : !!hotel?.passengers
      ? hotel.passengers || []
      : !!car?.passengers
        ? car.passengers || []
        : []
  const notGuestIds = (mainTripUsers || [])
    .filter((u) => !u?.isGuest && !!u.uniqueId)
    .map((u) => u.uniqueId)

  const params = new URLSearchParams(window.location.search)
  const paymentCompleted = params.has('completed')
  const paymentCancelled = params.has('cancel')

  const {
    netsPaymentId,
    netsCompleted,
    paiwiseCheckoutId,
    paiwiseRedirectUrl,
  } = useSelector((state) => state.checkout)

  const hotelPublicUsersInitials = useMemo(() => {
    let initials = null
    let validation = null

    const tmpPassengers = hotel?.passengers || []
    if (items.length > 1 || !tmpPassengers?.length)
      return { initials, validation }
    // public and not updated users
    const publicPassengers = tmpPassengers.filter(
      (p) => p?.isPublic && !p?.isUpdated
    )
    if (!publicPassengers?.length) return { initials, validation }
    initials = getPublicUsersFormikInitials(publicPassengers)
    const isFlight = items.find((item) => item.type === 'Flight')
    const validateBirthDate = !isAuthenticated && showBirthDate && isFlight
    validation = getPublicUsersValidationSchema(
      countryPhone?.code,
      validateBirthDate
    )
    initials.addressDetails.showAddressDetails = showAddressDetails
    return { initials, validation }
  }, [items, hotel])

  const costValidation = useMemo(() => {
    const { costfields: costArray = [] } = costfields || {}
    const validation = {}

    costArray.forEach((cost) => {
      let tmpYup = Yup.string()
      tmpYup = cost.required
        ? tmpYup.required(t('required field'))
        : tmpYup.nullable()

      if (cost.type === 'regex') {
        let regex = cost.regex
        if (!regex.startsWith('^')) {
          regex = `^${regex}`
        }
        if (!regex.endsWith('$')) {
          regex = `${regex}$`
        }
        tmpYup = tmpYup.matches(new RegExp(regex, 'i'), {
          message: t('invalid format'),
          excludeEmptyString: true,
        })
        validation[cost.costType] = tmpYup
        return
      }

      if (cost.type !== 'withFormat') {
        validation[cost.costType] = tmpYup
        return
      }

      let maxChrs = 0
      if (cost.charLimit === 'exact' && cost.exactChars) {
        tmpYup = tmpYup.length(cost.exactChars, t('validation exact length'))
        maxChrs = parseInt(cost.exactChars)
      } else {
        if (cost.minChars)
          tmpYup = tmpYup.min(cost.minChars, t('validation min length'))
        if (cost.maxChars)
          tmpYup = tmpYup.max(cost.maxChars, t('validation max length'))
        maxChrs = parseInt(cost.maxChars)
      }

      if (cost.textFormat === 'digit') {
        tmpYup = tmpYup.matches(/^[0-9\s]*$/, t('validation only numbers'))
      } else if (cost.textFormat === 'letter') {
        tmpYup = tmpYup.matches(/^[A-z\s]*$/, t('validation only letters'))
      } else if (cost.textFormat === 'mix' && maxChrs > 0) {
        const regChars = '\\.[]{}()*+-?^$|'
        let regGroups = ''
        let anySmblCount = 0

        let prevGroup = null
        let grCount = 0

        for (let smbl = 0; smbl < maxChrs; smbl++) {
          if (!cost?.validations?.[smbl]) break
          const isSymbols = cost?.validations?.[smbl]?.letter || false
          const isDigits = cost?.validations?.[smbl]?.digit || false
          const format = (cost?.validations?.[smbl]?.format || '').trim()
          const symbolsList = (cost?.validations?.[smbl]?.format || '')
            .replace(/[\s,]/gi, '')
            .split('')
          let tmpRegGroup = ''
          if (!isSymbols && !isDigits && !format) {
            tmpRegGroup = `a-z0-9`
            anySmblCount++
          } else {
            if (isSymbols) tmpRegGroup = 'a-z'
            if (isDigits) tmpRegGroup += '0-9'
            if (symbolsList.length) {
              tmpRegGroup += symbolsList
                .map((sm) =>
                  sm !== '' && regChars.indexOf(sm) >= 0 ? '\\' + sm : sm
                )
                .join('')
            }
          }

          if (!prevGroup) {
            prevGroup = tmpRegGroup
            grCount = 1
          } else if (prevGroup === tmpRegGroup) {
            grCount++
          } else {
            const rgCount = grCount > 1 ? `{${grCount}}` : ''
            regGroups += `([${prevGroup}]|$)${rgCount}`
            prevGroup = tmpRegGroup
            grCount = 1
          }
        }
        if (prevGroup) {
          const rgCount = grCount > 1 ? `{${grCount}}` : ''
          regGroups += `([${prevGroup}]|$)${rgCount}`
        }

        if (regGroups !== '' && anySmblCount < maxChrs) {
          const reg = new RegExp('^' + regGroups + '$', 'i')
          tmpYup = tmpYup.matches(reg, t('invalid format'))
        }
      }
      validation[cost.costType] = tmpYup
    })
    return validation
  }, [t, costfields])

  const hasTrain = items.some((i) => i.type === 'Train')

  const travellersValidation = useMemo(() => {
    if (isAuthenticated) {
      return {
        travellers: Yup.array().of(
          Yup.object().shape({
            ...(isFlightRequireDocs
              ? {
                sex: Yup.string().when('airlineRequiresDocs', {
                  is: true,
                  then: (schema) => schema.required(i18n.t('required field')),
                }),
                nationality: Yup.string().required(i18n.t('required field')),
                birthDate: Yup.object().when('airlineRequiresDocs', {
                  is: true,
                  then: (schema) =>
                    schema.shape({
                      y: Yup.string().required(i18n.t('required field')),
                      m: Yup.string().required(i18n.t('required field')),
                      d: Yup.string().required(i18n.t('required field')),
                    }),
                }),
              }
              : {}),
            costfields: Yup.object().shape(costValidation),
            mobile: hasTrain
              ? Yup.string().required(i18n.t('required field'))
              : null,
          })
        ),
      }
    }

    return {}
  }, [costValidation])

  useEffect(() => {
    dispatch(getCostfieldsAction(!!notGuestIds.length ? notGuestIds : null))
    dispatch(getHotelGuaranteeAction(hotel.room ? hotel.room : null))

    // When not logged in, attempt to fetch payment data.
    if (useExternalPayment && !paymentCompleted) {
      dispatch(getPaymentData(items))
    }
    // eslint-disable-next-line
  }, [dispatch, hotel.room])

  useEffect(() => {
    const timerId = setTimeout(() => {
      getCCFee({ items, fop, creditCard, costfields })
        .then((data) => dispatch(setCCFees(data)))
        .catch((e) => dispatch(setCCFees({})))
    }, 400)

    return () => {
      clearTimeout(timerId)
    }
  }, [dispatch, items, fop, creditCard, costfields])

  useEffect(() => {
    if (!!bookingResult) {
      if (!!user?.isBookingLink) dispatch(logOutUser())
      history.push('/book-result')
    }
  }, [bookingResult, history, dispatch, user])

  /**
   * Handle Nets widget callback.
   */
  useEffect(() => {
    if (netsCompleted) {
      // Proceed with booking
      formikRef.current.handleSubmit()
    }
  }, [netsCompleted])

  /**
   * Handle redirects from Paiwise.
   *
   * Query string parameters "completed" when payment is ready. "cancel" when user cancelled.
   */
  useEffect(() => {
    // Do the booking
    if (paymentCompleted && !guaranteeLoading) {
      setTimeout(() => {
        console.log('submitting')

        // Send message to iframe parent
        if (window.parent !== window) {
          window.parent.postMessage({ type: 'submitting' }, '*')
        }

        formikRef.current.handleSubmit()
      }, 500)
    }

    if (paymentCancelled) {
      // Do something?
    }
  }, [paymentCompleted, paymentCancelled, guaranteeLoading])

  const goBack = () => history.goBack()

  const updateUsersIfNeeded = async (values) => {
    const { users, email = null, phone = null } = values || {}

    if (email && phone) dispatch(setPublicContacts({ email, phone })) // set public booking contacts data
    if (!!users?.length) {
      const fields = {}
      users.forEach((u) => {
        if (!!u?.uniqueId) {
          fields[u.uniqueId] = { ...u }
        }
      })
      await dispatch(setItemsPassengerField(fields))
    }
  }

  const onClickBooking = () => {
    formikRef.current.validateForm().then((errors) => {
      if (errors?.travellers) {
        checkoutRef.current.scrollTo('travellers')
      } else if (errors?.approver) {
        checkoutRef.current.scrollTo('approver')
      }

      formikRef.current.handleSubmit()
    })
  }

  const onBooking = async (values) => {
    if (paiwiseCheckoutId && !paymentCompleted) {
      // Redirect to Paiwise
      await updateUsersIfNeeded(values)
      window.location = paiwiseRedirectUrl
    } else {
      // Proceed with booking
      generalBooking(values)
    }
  }

  const updateItemsFromTravellers = async (values) => {
    const { travellers } = values

    // Copy loyalty field if set.
    const fields = {}
    items.forEach((item) => {
      if (item.passengers) {
        item.passengers.forEach((passenger) => {
          const traveller = travellers?.find(
            (t) => t?.uniqueId === passenger?.uniqueId
          )
          if (traveller && traveller.loyalty) {
            passenger.loyalty = traveller.loyalty
            fields[t.uniqueId] = { loyalty: traveller.loyalty }
          }
        })
      }
    })

    await dispatch(setItemsPassengerField(fields))
  }

  const generalBooking = async (values) => {
    const fetchCheckout = new CheckoutFetch()
    if (bookingProcess || guaranteeLoading) return false

    let extraData = {}

    // Bizpart light users
    if (storage.getItem('guestIdentifier')) {
      extraData.guestIdentifier = storage.getItem('guestIdentifier')
    }

    // Bizpart white label users
    const virtualCustomerIdentifier = getVirtualCustomer()
    if (virtualCustomerIdentifier) {
      extraData.customerIdentifier = virtualCustomerIdentifier

      // Special validation for DLK
      let virtualCustomerConfig = getVirtualCustomerConfig()
      if (virtualCustomerConfig.showDLK) {
        // Validate
        if (!store.checkout.dlk?.teamId) {
          let newVal = { ...store.checkout.dlk }
          dispatch(setDlk(newVal.assocId, newVal.teamId, true))
          return
        }

        // OK
        extraData.siteParams = { ...store.checkout.dlk }
      }
    }

    dispatch(toggleBookingProcess(true))
    const {
      users: usersData = [],
      email = null,
      phone = null,
      eticketDelivery,
      travellers,
      approver
    } = values || {}
    if (email && phone) dispatch(setPublicContacts({ email, phone })) // set public booking contacts data

    if (isFlightRequireDocs && isAuthenticated) {
      items.map((item) => {
        item.passengers = item?.passengers?.map((user) => {
          const traveller = travellers?.find(
            (t) => t?.uniqueId === user?.uniqueId
          )
          const tmpDate = new moment(
            `${traveller?.birthDate.y}-${traveller?.birthDate.m}-${traveller?.birthDate.d}`,
            'YYYY-MM-DD'
          )
          user.birthDate = tmpDate.isValid() ? tmpDate.format('YYYY-MM-DD') : ''
          user.nationality = traveller?.nationality
          user.sex = traveller?.sex
          user.airlineRequiresDocs = !passengerData?.[user?.uniqueId]?.docExists
          return { ...user }
        })
        return { ...item }
      })
    }

    await updateItemsFromTravellers(values)

    try {
      const bookData = prepareBooking(items, store, {
        usersData,
        eticketDelivery,
      })
      const hasGuest = travellers?.some((traveller) => traveller.isGuest)
      bookData.hasGuest = hasGuest

      Object.assign(bookData, extraData)

      // Policy exception reason
      if (store.checkout.policyExceededReason) {
        bookData.policyExceededReason = store.checkout.policyExceededReason
      }

      if (needApprove && !approver.uniqueId) {
        throw new cityCityException({ message: 'approver wasnt selected' })
      }

      if (needApprove && !!approver.uniqueId) {
        bookData.approvers = [approver.uniqueId]
      }
      //console.log('bookData', bookData)
      //throw new Error('test test test')

      const data = needApprove ? await fetchCheckout.sendToApprove(bookData) : await fetchCheckout.bookingAll(bookData);

      if (!data?.results || typeof data?.results !== 'object') {
        throw new cityCityException({ message: 'booking failed' })
      }
      const resData = { ...data.results }
      if (!!needApprove) {
        resData.isApprove = true;
        resData.approver = approver;
      }
      resData.extData = {
        passengers: [...mainTripUsers],
        confirmEmails: !!needApprove ? [approver.email] : data?.results?.all?.confirmEmails,
      }

      if (!!user?.isBookingLink) {
        resData.extData = {
          ...resData.extData,
          email: user?.email,
          supportTelephone: user?.supportTelephone,
          isBookingLink: true,
        }
      }

      dispatch(setBookingData(resData))
    } catch (e) {
      console.log(e)
      const code = e?.response?.data?.code
      dispatch(setBookingData(null))

      if (e?.errorType === 'citycity validation') {
        setError({ error: e.message })
      } else if (e?.errorType !== 'citycity warning') {
        setError(code ? e.response.data : true)
      } else if (e?.subtype === 'user.noPhone') {
        checkoutRef.current.scrollTo('travellers')
      }
    }
    dispatch(toggleBookingProcess(false))
  }

  const buildCostInitial = (uId) => {
    const { costfields: costArray = [] } = costfields || {}
    const resValues = { ...(costFieldValues?.[uId] || {}) }

    costArray.forEach((cost) => {
      if (typeof resValues[cost.costType] !== 'undefined') return
      resValues[cost.costType] = ''
    })
    return resValues
  }

  const validationSchema = Yup.object().shape({
    ...(!isCcNeeded || hideHotelGuarantee || netsPaymentId || paiwiseCheckoutId
      ? {}
      : {
        cardNumber: Yup.string()
          .nullable()
          .required(t('required field'))
          .test(
            'test-number',
            t('invalid card'),
            (value) => valid.number(value).isValid
          ),
        date: Yup.string()
          .nullable()
          .required(t('required field'))
          .test(
            'test-date',
            t('invalid date'),
            (value) => valid.expirationDate(value).isValid
          ),
        cvv: guarantee
          ? Yup.string().nullable()
          : Yup.string()
            .nullable()
            .required(t('required field'))
            .test(
              'test-cvv',
              t('invalid cvc'),
              (value) => valid.cvv(value).isValid
            ),
      }),
    ...(hotelPublicUsersInitials.validation || {}),
    ...(travellersValidation || {}),
    ...(
      needApprove ? {
        approver: Yup.object().nullable().required(t('required field'))
      } : {}
    ),
  })

  //train ticket delivery
  let trnTKTD = 'email'
  const deliveryException = getDelivException(store.checkout)

  if (!!deliveryException) {
    trnTKTD = deliveryException
  } else {
    trnTKTD =
      checkoutItem?.type === 'Train' && !!user?.trainTKTDelivery
        ? user?.trainTKTDelivery
        : 'email'
  }

  // NDS - disabled for now
  // const trnTKTDTypes = ['email', 'phone']
  const trnTKTDTypes = ['email']
  trnTKTD = trnTKTDTypes.includes(trnTKTD) ? trnTKTD : 'email'

  const initialValues = useMemo(
    () => ({
      eticketDelivery: trnTKTD,
      cardNumber: creditCard.number,
      date: creditCard.expire,
      cvv: creditCard.cvv,
      ccCode: creditCard.ccCode,
      ...(hotelPublicUsersInitials.initials || {}),
      costfields: {},
      ... (needApprove ? { approver: null } : {}),
      travellers: mainTripUsers.map((u) => {
        let bd = null
        const fetchedProf = passengerData?.[u?.uniqueId]
        if (
          !!fetchedProf?.profile?.dob &&
          typeof fetchedProf.profile.dob === 'string'
        ) {
          bd = moment(fetchedProf.profile.dob)
          if (!bd.isValid()) bd = null
        } else if (!!u.birthDate && typeof u.birthDate === 'string') {
          bd = moment(u.birthDate)
          if (!bd.isValid()) bd = null
        }
        const country = countriesList?.find(
          (c) => c.code === fetchedProf?.profile?.country || ''
        )
        return {
          ...u,
          airlineRequiresDocs: !fetchedProf?.docExists,
          costfields: buildCostInitial(u?.uniqueId),
          sex: fetchedProf?.profile?.gender || '',
          nationality: country?.alpha3Code || 'SWE',
          birthDate: {
            y: !!bd ? bd.format('YYYY') : '',
            m: !!bd ? bd.format('MM') : '',
            d: !!bd ? bd.format('DD') : '',
          },
        }
      }),
    }),
    // eslint-disable-next-line
    [costfields, hotelPublicUsersInitials, countriesList, passengerData]
  )

  const bookingsDisabled = costfields?.bookingsDisabled

  let bookingButtonLabel = ''
  if (needApprove) {
    bookingButtonLabel = t('send for approval');
  } else {
    bookingButtonLabel = paiwiseCheckoutId
      ? t('payment proceed')
      : t('payment pay')
  }

  let shoppingCartTop = 127
  if (isIframed) shoppingCartTop = 90
  if (!!user?.isBookingLink || ['agent', 'viaAgent'].includes(authType))
    shoppingCartTop += 49

  return (
    <GuaranteeContext.Provider value={{ setGuarantee }}>
      <Formik
        enableReinitialize
        onSubmit={onBooking}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box pb={40}>
              {isDesktop ? (
                <DesktopDetailsHeader tripPrice={sum} />
              ) : (
                <MobileHeader tripPrice={sum} />
              )}
              <Container maxWidth="lg" disableGutters>
                <Box mt={isDesktop ? 4 : 1} p={isDesktop ? 1 : 1}>
                  <Grid
                    container
                    spacing={isDesktop ? 6 : 2}
                    direction={isDesktop ? 'row' : 'column-reverse'}
                  >
                    <Grid item xs={12} sm={7}>
                      <CheckoutBlock
                        items={items}
                        fops={fops}
                        isCcNeeded={isCcNeeded}
                        ref={checkoutRef}
                      />

                      {!netsPaymentId && ( // Hide payment button when using Nets
                        <Box className={classes.btnContainer}>
                          {isDesktop ? (
                            <Button
                              disableElevation
                              disableRipple
                              onClick={goBack}
                              startIcon={
                                <ArrowBackOutlinedIcon color="primary" />
                              }
                              className={classes.backBtn}
                              variant="contained"
                            >
                              {t('go back')}
                            </Button>
                          ) : (
                            ''
                          )}
                          <Button
                            disabled={bookingsDisabled}
                            disableElevation
                            type="button"
                            onClick={onClickBooking}
                            color="primary"
                            variant="contained"
                            className={classes.forwardBtn}
                          >
                            {bookingProcess
                              ? t('booking wait')
                              : bookingButtonLabel}
                          </Button>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Box
                        className={isDesktop ? classes.fixedDiv : ''}
                        style={{ top: isDesktop ? shoppingCartTop : null }}
                      >
                        <ViewHandler />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <BookingProcessModal isApprove={needApprove} />
              <BookingFailModal
                isApprove={needApprove}
                submit={handleSubmit}
                error={error}
                setIsOpened={setError}
              />
            </Box>
          </form>
        )}
      </Formik>
    </GuaranteeContext.Provider>
  )
}

export default memo(Checkout)
