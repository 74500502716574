import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchHotelCitiesAction,
  resetHotelFilters,
  setHotelGuestsAction,
  setMapCenter,
  setSelectedHotel,
} from '../../store/hotels/hotelsAction'
import {
  fetchHotelSearchAction,
  fetchHotelRatingsAction,
} from '../../store/hotels/hotelsAction'
import HotelSearchContent from '../../containers/hotels/result/hotelSearchContent'
import MinimizedSearchbar from '../../containers/search/mimizedSearch'
import HotelSkeletons from '../../components/reusable/skeletons/hotelSkeletons'
import NoTripsFound from '../../components/general/noTripsFound'
import { toFullDate } from '../../utils/formatDates'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MobilerHeader from '../../containers/header/mobileHeader/mobileHeader'
import { hotelBreadCrumbAction } from '../../store/breadcrumbs/breadcrumbsAction'
import { removeAllCheckoutAction } from '../../store/checkout/checkoutActions'
import useCityChoose from '../../hooks/useCityChooseHook'
import useBookingDates from '../../hooks/useBookingDatesHook'
import moment from 'moment'
import useHotels from '../../hooks/useHotelsHook'
import { buildUsersByCount } from '../../utils/general'
import { setHotelSearchParameters } from '../../store/search/searchAction'
import storage from 'local-storage-fallback'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  hotelsearchOuter: {
    minHeight: 'calc(100vh - 65px)',
    width: '100%',
    display: 'flex',
    zIndex: 1
  },
}))

const HotelSearch = () => {
  const { pathname } = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const isSearchLoading = useSelector((state) => state?.hotels.isSearchLoading)
  const searchError = useSelector((state) => state?.hotels.searchError)
  const cities = useSelector((state) => state?.hotels.cities)
  const city = useSelector((state) => state?.hotels.city)
  const guests = useSelector((state) => state?.hotels.guests)
  const showMap = useSelector((store) => store?.hotels.showMap)
  const searchData = useSelector((state) => state.search)
  const bedsCount = useMemo(() => {
    return searchData?.hotels?.travelerCounts?.beds?.count || 1
  }, [searchData?.hotels])

  const { isAuthenticated, user } = useSelector((state) => state.auth)

  const earliestDate = user?.bookingLink?.earliestDate
  const latestDate = user?.bookingLink?.latestDate
  const isMediumSize = useMediaQuery(theme.breakpoints.down('md'))

  const { city: cityValue, setCityOnChange } = useCityChoose(
    city,
    dispatch,
    params
  )
  const { checkIn, checkOut, depChange, arrOnChange } = useBookingDates(
    moment(params.checkIn),
    moment(params.checkOut),
    earliestDate,
    latestDate
  )

  const [travellers, setTravellers] = useState(guests)
  const [roomCount, setRoomCount] = useState(1)

  useEffect(() => {
    if (!checkIn || !checkOut) {
      depChange(moment(params.checkIn))
      arrOnChange(moment(params.checkOut))
    }
  }, [
    checkIn,
    checkOut,
    arrOnChange,
    depChange,
    params.checkIn,
    params.checkOut,
  ])

  useEffect(() => {
    dispatch(hotelBreadCrumbAction(pathname))
  }, [dispatch, pathname])

  useEffect(() => {
    setRoomCount(parseInt(params.roomCount))
  }, [params.checkIn, params.checkOut, params.guests, guests, params.roomCount])

  useEffect(() => {
    const payload = {
      countryCode: params.countryCode,
      lat: params.lat,
      lng: params.lng,
      checkIn: params.checkIn,
      checkOut: params.checkOut,
      guests: bedsCount,
      roomCount: params.roomCount,
    }
    dispatch(setMapCenter(null))
    dispatch(fetchHotelSearchAction(payload))
  }, [
    params.countryCode,
    params.lat,
    params.lng,
    params.checkIn,
    params.checkOut,
    bedsCount,
    params.roomCount,
    dispatch,
  ])

  const incrementRoom = () => {
    setRoomCount((prev) => ++prev)
  }

  const decrementRoom = () => {
    setRoomCount((prev) => (prev === 1 ? 1 : --prev))
  }

  const { counts, modifyCounts } = useHotels({
    travellers,
    roomCount,
    incrementRoom,
    decrementRoom,
    setRoomCount,
  })

  useEffect(() => {
    dispatch(
      fetchHotelRatingsAction({
        lat: params.lat,
        lng: params.lng,
        countryCode: params.countryCode,
      })
    )

    if (travellers.length === 0) {
      // Direct link. We have no travellers in Redux state. Quick fix is to save our generated default travellers in state.

      const tmpTravelers = isAuthenticated
        ? travellers
        : buildUsersByCount(counts)
      dispatch(setHotelGuestsAction(tmpTravelers))
    }
  }, [dispatch, params.lat, params.lng, params.countryCode])

  const onSubmit = async () => {
    const tmpTravelers = isAuthenticated
      ? travellers
      : buildUsersByCount(counts)
    storage.removeItem('transfer')
    storage.removeItem('transfer_phone')
    dispatch(setHotelGuestsAction(tmpTravelers))
    dispatch(removeAllCheckoutAction())
    dispatch(setSelectedHotel(null))
    dispatch(resetHotelFilters())
    await dispatch(setMapCenter(null))

    // remove unnecessary counts data
    const countsForSave = {}
    for (let countKey in counts) {
      countsForSave[countKey] = {
        count: counts[countKey].count || 0,
      }
    }

    dispatch(
      setHotelSearchParameters(
        cityValue,
        city,
        checkIn,
        checkOut,
        tmpTravelers,
        roomCount,
        countsForSave
      )
    )

    const { lat, lng } = city?.Coordinates
    const uri = `/hotels/search/${city.Country.length > 2 ? params.countryCode : city.Country
      }/${lat}/${lng}/${toFullDate(checkIn)}/${toFullDate(
        checkOut
      )}/${roomCount}/${tmpTravelers.length}`
    history.push(uri)
  }

  const optionLabel = (options) => {
    if (!options) return ''
    return options.Name ? options.Name : options.Location
  }

  const getCities = (value) => dispatch(fetchHotelCitiesAction(value))

  const isFullWidth = !!isMobile || !showMap
  const innerStyles = {
    width: isFullWidth ? '100%' : '820px',
    paddingLeft: isFullWidth ? '0' : '20px',
    maxWidth: '1200px'
  }
  const outerStyles = {
    justifyContent: isFullWidth ? 'center' : 'flex-start',
  }

  return (
    <>
      {isMobile && !showMap && <MobilerHeader city={cityValue} />}
      <MinimizedSearchbar
        from={cityValue}
        setFrom={setCityOnChange}
        departure={checkIn}
        setDeparture={depChange}
        arrival={checkOut}
        setArrival={arrOnChange}
        /* */
        travellers={travellers}
        setTravellers={setTravellers}
        getCities={getCities}
        onSubmit={onSubmit}
        array={cities}
        optionLabel={optionLabel}
        setHotel={setCityOnChange}
        roomCount={roomCount}
        incrementRoom={incrementRoom}
        decrementRoom={decrementRoom}
        HOTEL
        counts={counts}
        modifyCounts={modifyCounts}
        earliestDate={earliestDate}
        latestDate={latestDate}
      />
      <>
        {searchError ? (
          <NoTripsFound isHotel={true} />
        ) : (
          <Box style={outerStyles} className={classes.hotelsearchOuter}>
            <Box
              mb={10}
              style={innerStyles}
            >
              <HotelSearchContent
                lat={params.lat}
                lng={params.lng}
                mapFieldPosition={{ top: 0, right: 0 }}
              />
              {isSearchLoading && !showMap && <HotelSkeletons />}
            </Box>
          </Box>
        )}
      </>
    </>
  )
}

export default HotelSearch
