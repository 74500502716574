import { useCallback, useEffect, useRef } from 'react'

type MultiScrollbarOptions = {
  initialScroll?: number
}

// usage example: src/pages/my-trips/tripsCalendars.js
export const useMultiScrollbar = (options: MultiScrollbarOptions = {}, deps = []) => {
  const refTopScrollbar = useRef(null)
  const refContent = useRef(null)
  const refTopScrollbarInner = useRef(null)
  const initialScrollApplied = useRef(false)

  const onTopScrollbarScroll = useCallback((e) => {
    if (refContent.current) refContent.current.scrollLeft = e.target.scrollLeft
  }, [refContent])

  const onContentScroll = useCallback((e) => {
    if (refTopScrollbar.current) refTopScrollbar.current.scrollLeft = e.target.scrollLeft
  }, [refTopScrollbar])

  useEffect(() => {
    // set scrollbar width to content width via inner-content hack
    if (refContent.current && refTopScrollbarInner.current) {
      refTopScrollbarInner.current.style.width = `${refContent.current.scrollWidth}px`

      if (options.initialScroll && !initialScrollApplied.current) {
        initialScrollApplied.current = true
        refContent.current.scrollLeft = options.initialScroll
      }
    }
  }, [refTopScrollbarInner.current, refContent.current, ...deps])

  return {
    refTopScrollbar,
    refContent,
    refTopScrollbarInner,
    onTopScrollbarScroll,
    onContentScroll
  }
}

export const useIsMounted = () => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}
