import React, { memo, useEffect, useState } from 'react'
import AuthWrapper from '../../containers/authWrapper/authWrapper'
import {
  Box,
  Backdrop,
  CircularProgress,
  makeStyles,
  useTheme,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddMissingUserData from '../../containers/authentication/addMissingUserData'
import { fetchRegisterUser } from '../../repositories/auth'
import { useDispatch } from 'react-redux'
import { isLogginIn, relogin } from '../../store/auth/authAction'
import { openEditInfoModal } from '../../store/modals/modalAction'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles((theme) => ({
  loadingBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  errorText: {
    marginTop: '20px',
    fontSize: '14px',
    color: theme.palette.error.main,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  otpField: {
    textAlign: 'center',
    borderRadius: '4px',
    fontWeight: theme.typography.fontWeightBold,

    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      backgroundColor: "#eee",
      color: "black"
    }
  },
  title: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
}))

const Register = () => {
  const location = useLocation();
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()

  const [userData, setUserData] = useState({
    email: location.state?.email,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [showOTP, setShowOTP] = useState(false)
  const [otp, setOTP] = useState(null)

  const completeLogin = async (loginData) => {
    await dispatch(isLogginIn(true))
    await dispatch(relogin(loginData))
    await dispatch(isLogginIn(false))
  }

  const openPasswordModal = async () => {
    await dispatch(
      openEditInfoModal({
        name: t('nav pass'),
        rows: [
          { title: t('new password'), key: 'password', order: 1 },
          { title: t('confirm password'), key: 'confirmpassword', order: 2 },
        ],
        section: 'password',
      })
    )
  }

  const handleSaveUserData = (data) => {
    setUserData(data)
    setIsLoading(true)

    fetchRegisterUser(data)
      .then((result) => completeLogin(result))
      .then(() => openPasswordModal())
      .catch((error) => {
        console.log(error)
        const errorCode = error.response?.data?.code
        const errorMessage = error.response?.data?.error

        if (errorCode === 'ERROR_OTP_REQUIRED') {
          setError(t(errorCode, { email: data.email }))
          setShowOTP(true)
        } else if (errorCode) {
          setError(t(errorCode))
        } else {
          setError(errorMessage || error.toString())
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleKeyDown = (e) => {
    if (!['Backspace', 'Delete'].includes(e.key)) {
      if (false || e.key && !e.key.match(/[\d\b]/)) {
        e.preventDefault()
      }
    }
  }

  const handlePaste = (e) => {
    const text = e.clipboardData.getData('Text').replace(/[^0-9]/gm, '').substring(0, 6)
    setOTP(text)
    e.preventDefault()
  }

  const handleOtpChanged = (e) => {
    setOTP(e.target.value)
  }

  // Re-submit when OTP is fully entered
  useEffect(() => {
    if (otp?.length == 6) {
      handleSaveUserData({ ...userData, otp })
    }
  }, [otp])

  return (
    <AuthWrapper>
      {!showOTP && (
        <Box>
          <AddMissingUserData
            title={t('register user title')}
            description={t('register user description')}
            user={userData}
            editCompletedData={true}
            isLoading={isLoading}
            onSubmit={handleSaveUserData}
            isSubmitDisabled={showOTP}
          />
        </Box>
      )}

      {showOTP && (
        <Box mt={3}>
          <Typography className={classes.title} variant="h4">
            {t('enter otp code')}
          </Typography>
        </Box>
      )}

      {error && <Box p={2} className={classes.errorText}>{error}</Box>}

      {showOTP && (
        <Box mt={3}>
          <TextField
            fullWidth
            value={otp}
            autoComplete={'off'}
            inputProps={{ autoCorrect: 'off', spellCheck: 'false', maxLength: 6, style: { fontWeight: 500 } }}
            className={classes.otpField}
            placeholder='000000'
            variant='outlined'
            onKeyPress={handleKeyDown}
            onChange={handleOtpChanged}
            onPaste={handlePaste}
          />
        </Box>
      )}

      {isLoading && (
        <Backdrop className={classes.loadingBackdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </AuthWrapper>
  )
}

export default memo(Register)
