import React, { Fragment, useCallback, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { validatePhoneNumber } from '../../utils/general'
import { useMask } from '@react-input/mask'
import { isDate, isFuture, isValid, parse } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: '1.1px',
    borderRadius: '22px',
    padding: '8px 22px 8px 22px',
  },
  title: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  text: {
    textAlign: 'center',
  },
  errorText: {
    fontSize: '14px',
    color: theme.palette.error.main,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  logo: {
    maxHeight: '400px',
    maxWidth: '400px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      maxWidth: '200px',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  inputField: {
    borderRadius: '4px',

    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      backgroundColor: "#eee",
      color: "black"
    }
  },

  placeholderContainer: {
    position: 'relative',
    width: '100%',
  },
  inputBase: {
    '& input': {
      position: 'relative',
      background: 'transparent',
      zIndex: 1,
    },
  },
  placeholderOverlay: {
    position: 'absolute',
    top: '9px',
    left: '2px',
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '16.5px 14px',
    fontSize: '16px',
    color: theme.palette.text.disabled,
    pointerEvents: 'none',
  },
}))

const AddMissingUserData = ({
  title,
  description,
  user,
  editCompletedData = false,
  isLoading,
  onSubmit,
  isSubmitDisabled = false
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [email, setEmail] = useState(user.email)
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [phone, setPhone] = useState(user.phone)
  const [namePrefix, setNamePrefix] = useState(user.namePrefix)
  const [birthdate, setBirthdate] = useState(user.birthdate)
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    other: null,
    phone: null,
    birthdate: null,
    namePrefix: null,
  })
  const birthdateRef = useMask({
    mask: 'YYYY-MM-DD',
    replacement: {
      Y: /[0-9]/, // Any digit for year
      M: /[0-9]/, // First digit of month
      D: /[0-9]/, // First digit of day
    },
    // validate: (value) => validateDate(value),
  })

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(String(email).toLowerCase())
  }

  const validateDate = (dateStr) => {
    // Parse the date string using date-fns
    const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date())

    // Check if it's a valid date
    if (!isValid(parsedDate) || !isDate(parsedDate) || isFuture(parsedDate)) {
      return false
    }

    return true
  }

  const handleSubmit = () => {
    // Validate fields
    const newErrors = {}

    if (!validateEmail(email)) newErrors.email = true
    if (!validatePhoneNumber(phone)) newErrors.phone = true
    if (!/\S{2,}/.test(firstName)) newErrors.firstName = true
    if (!/\S{2,}/.test(lastName)) newErrors.lastName = true
    if (!namePrefix) newErrors.namePrefix = true
    if (!validateDate(birthdate)) newErrors.birthdate = true

    if (Object.keys(newErrors).length > 0) return setErrors(newErrors)

    setErrors({})

    onSubmit({
      email,
      firstName,
      lastName,
      phone,
      namePrefix,
      birthdate,
    })
  }

  const renderPlaceholder = useCallback(() => {
    const format = '1980-01-01'
    if (!birthdate) return null
    return format
      .split('')
      .map((char, i) => {
        if (i < birthdate.length) {
          return '\u2000' // Use an em space to match the width of typed characters
        }
        return char
      })
      .join('')
  }, [birthdate])

  return (
    <Box className={classes.wrapper}>
      <Fragment>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {description}
        </Typography>

        <Box mb={2} mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                margin="normal"
                error={!!errors.namePrefix}
              >
                <InputLabel>{t('profile title')}</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  label={t('profile title')}
                  value={namePrefix}
                  onChange={(e) => setNamePrefix(e.target.value)}
                  className={classes.inputField}
                  disabled={user.namePrefix && !editCompletedData}
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {/* <MenuItem value={''}>{t('profile title')}</MenuItem> */}
                  <MenuItem value="MR">Mr</MenuItem>
                  <MenuItem value="MS">Ms</MenuItem>
                  <MenuItem value="MRS">Mrs</MenuItem>
                </Select>
                {errors.namePrefix && (
                  <FormHelperText error>
                    {t('name prefix required')}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('profile name')}
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="normal"
                error={!!errors.firstName}
                className={classes.inputField}
                helperText={errors.firstName && t('first name required')}
                disabled={user.firstName && !editCompletedData}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label={t('profile surname')}
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="normal"
                error={!!errors.lastName}
                className={classes.inputField}
                helperText={errors.lastName && t('last name required')}
                disabled={user.lastName && !editCompletedData}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label={t('email form')}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            error={!!errors.email}
            className={classes.inputField}
            disabled={user.email && !editCompletedData}
            helperText={errors.email && t('invalid email')}
          />

          <TextField
            fullWidth
            label={t('contact mobiltelefon')}
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            error={!!errors.phone}
            className={classes.inputField}
            helperText={errors.phone && t('invalid format')}
            placeholder="+46707123455"
            disabled={user.phone && !editCompletedData}
          />

          <div className={classes.placeholderContainer}>
            <TextField
              fullWidth
              label={t('profile date')}
              variant="outlined"
              value={birthdate}
              onChange={(e) => setBirthdate(e.target.value)}
              margin="normal"
              error={!!errors.birthdate}
              className={`${classes.inputField} ${classes.inputBase}`}
              placeholder="1980-01-01"
              disabled={user.birthdate && !editCompletedData}
              InputProps={{
                inputRef: birthdateRef,
                style: {
                  fontFamily: 'inherit',
                  letterSpacing: 'inherit',
                },
              }}
            />
            <div className={classes.placeholderOverlay}>
              {renderPlaceholder()}
            </div>
          </div>
          {errors.birthdate && (
            <FormHelperText error>{t('invalid format')}</FormHelperText>
          )}
        </Box>

        {errors.other && (
          <Box className={classes.errorText}>{errors.other}</Box>
        )}

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => handleSubmit()}
            disableElevation
            disableRipple
            disabled={isLoading || isSubmitDisabled}
          >
            {t('save')}
          </Button>
        </Box>
      </Fragment>
    </Box>
  )
}

export default AddMissingUserData
