import React, { memo, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  deal: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    color: theme.palette.policyTextGreen,
    backgroundColor: theme.palette.policyGreen,
    fontWeight: 600,
  },
  dealSmall: {
    fontSize: theme.spacing(1.2),
    padding: theme.spacing(0.5, 1.5),
    color: theme.palette.policyTextGreen,
    fontWeight: 600,
  },

  corporate: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    color: theme.palette.policyTextBlue,
    backgroundColor: theme.palette.policyBlue,
    fontWeight: 600,
  },
  corporateSmall: {
    fontSize: theme.spacing(1.2),
    padding: theme.spacing(0.5, 1.5),
    color: theme.palette.policyTextBlue,
    fontWeight: 600,
  },

  policy: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
    fontWeight: 600,
  },
  policySmall: {
    fontSize: theme.spacing(1.2),
    padding: theme.spacing(0.5, 1.5),
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
}))

const Policy = ({
  ratePlanCode,
  policy,
  deal,
  corporate,
  small,
  rootStyles,
  labelStyles,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { agencyTitle, hideCorporateCodes, user } = useSelector(
    (state) => state.auth
  )
  const showCorporateCodeType = user?.showCorporateCodeType || false
  const corporateCodeAliases = user?.corporateCodeAliases || []

  const alias = useMemo(() => {
    let item = corporateCodeAliases.find(
      (code) => code.split(':')[1] === ratePlanCode
    )

    if (item) return item.split(':')[0]
  }, [corporateCodeAliases, ratePlanCode])

  if (policy)
    return (
      <Box mr={0} className={classes.root} style={rootStyles || {}}>
        <Typography
          style={{ textAlign: 'center', ...(labelStyles || {}) }}
          component={'span'}
          className={small ? classes.policySmall : classes.policy}
        >
          {t('out of policy')}
        </Typography>
      </Box>
    )

  if (deal)
    return (
      <Box mr={0} className={classes.root} style={rootStyles || {}}>
        <Typography
          component={'span'}
          style={labelStyles || {}}
          className={small ? classes.dealSmall : classes.deal}
        >
          {agencyTitle && hideCorporateCodes && showCorporateCodeType ? agencyTitle + ' ' : ''}
          {t('contract price')}{' '}
          {agencyTitle && !hideCorporateCodes
            ? `(${alias || agencyTitle})`
            : ''}
        </Typography>
      </Box>
    )

  if (corporate)
    return (
      <Box mr={0} className={classes.root} style={rootStyles || {}}>
        <Typography
          component={'span'}
          style={labelStyles || {}}
          className={small ? classes.dealSmall : classes.deal}
        >
          {t(showCorporateCodeType ? 'your contract price' : 'contract price')}{' '}
          {ratePlanCode && !hideCorporateCodes
            ? `(${alias || ratePlanCode})`
            : ''}
        </Typography>
      </Box>
    )

  return false
}

export default memo(Policy)
