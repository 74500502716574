import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Divider, Grid, Link, Button } from '@material-ui/core'
import moment from 'moment'
import { formatDuration } from '../../utils/formatDurations'
import COMPANIES from '../../constants/flightCompanies'
import defaultStyles from './defaultTimeline.styles'
import capitalize from '../../utils/capitalizeFirstLetter'
import {
  openSimpleInfoModal,
  setBookingPartCancelModalData,
} from '../../store/modals/modalAction'
import { useDispatch, useSelector } from 'react-redux'
import {
  canCancelBooking,
  getTripServiceTexts,
  prepareCancellationProhibitedModal,
} from '../../utils/general'
import airplaneModels from '../../constants/airplaneModels.json'
import Moment from 'react-moment'
import CustomIconButton from '../reusable/customIconButton'
import { ArrowForward, Clear } from '@material-ui/icons'
import GetCompanyIcon from '../reusable/getCompanyIcon'
import TripHeader from './tripHeader'
import { createPortal } from 'react-dom'
import capitalizeOnlyFirstLetter from '../../utils/capitalizeOnlyFirstLetter'

// takes an array of flights and produces a timeline
const FlightTimeLine = ({
  model,
  enableCancel = true,
  price = null,
  onClose,
  mainRef,
}) => {
  const dispatch = useDispatch()
  const classes = defaultStyles()
  const { t } = useTranslation()
  let itmKey = 1

  const { flights } = model
  const isTwoWay = flights.find((f) => f.type == 'returnTrip')
  const { user, currency } = useSelector((state) => state.auth)

  const outboundFlights = flights.filter((f) => f.type === 'trip')
  const returnFlights = flights.filter((f) => f.type === 'returnTrip')

  const onCancel = async () => {
    const { canCancel, reason } = canCancelBooking(user, model, ['flight'])
    if (!canCancel) {
      const modalData = prepareCancellationProhibitedModal(reason, model, user)
      return dispatch(openSimpleInfoModal(modalData))
    }

    dispatch(
      setBookingPartCancelModalData({
        type: 'flight',
        pnr: model?.pnr,
        model: model,
        passengers: model?.passengers,
        item: { ...flights, withReturn: isTwoWay },
      })
    )
  }

  const isSeparateCancelDisabled =
    model.netsPaymentId !== null || model.paiwiseCheckoutId

  const isCancelled = !!model && (flights[0].cancelled || model?.cancelled)
  const getCancelButton = () => {
    if (isSeparateCancelDisabled) return null
    if (!model?.pnr) return null
    if (isCancelled) {
      return <Box className={classes.cancelledText}>{t('cancelled')}</Box>
    }
    if (!model?.isUpcoming || !enableCancel) return null
    return (
      <CustomIconButton
        label={`${t('cancel booking')} ${t('flight').toLowerCase()}`}
        color="red"
        icon={<Clear />}
        onClick={(e) => {
          e.stopPropagation()
          onCancel()
        }}
      />
    )
  }

  const getTripRules = (dirKey) => {
    if (!model?.fareServices) return null

    const rules = []
    const servs = model.fareServices

    const dirSrv = servs[dirKey]
    if (!dirSrv || typeof dirSrv !== 'object') return null

    for (let ffName in dirSrv) {
      const ffSrvs = dirSrv[ffName]
      if (!ffSrvs?.length) continue

      for (let s of ffSrvs) {
        const text = t(s.text)

        if (['HBAG', 'BAG'].includes(s.type)) {
          rules.push(text)
        }
        if (['RBK', 'REF'].includes(s.type)) {
          rules.splice(0, 0, text)
        }
      }
    }

    const relevantFlightKeys = dirKey === 'outbound' ? outboundFlights.map((f) => f.uniqueInd) : returnFlights.map((f) => f.uniqueInd)

    // Extra bags
    for (const p of model?.body?.flight?.passengers || []) {
      for (const service of p.services || []) {
        const isIncludedInDirection = service.flightKeys?.some((key) => relevantFlightKeys?.includes(key))
        if (['HBAG', 'BAG', 'ABAG', 'XBAG'].includes(service.serviceCode) && isIncludedInDirection) {
          rules.push(
            `${capitalizeOnlyFirstLetter(t('additional checked bag'))} (${p.firstName} ${p.lastName})`
          )
        }
      }
    }

    return rules
  }

  const outboundTripRules = useMemo(() => getTripRules('outbound'), [model])
  const returnTripRules = useMemo(() => getTripRules('return'), [model])

  const renderTrip = (flights, isOutbound) => {
    const durationText = formatDuration(
      flights.reduce((sum, flight) => sum + flight.travelTime, 0)
    )
    const stops = flights.length - 1
    const stopText =
      stops > 0
        ? `${stops} ${t(stops > 1 ? 'stops' : 'stop')}`
        : t('filter directly')

    let texts = getTripServiceTexts(model, false, ['flight'])

    if (flights.length == 0) return null
    return (
      <Box className={classes.card}>
        <Box mt={1} mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <GetCompanyIcon
                height={20}
                width={20}
                company={flights[0].airline}
              />
            </Grid>
            <Grid item xs={10}>
              <Box class={classes.subTitle}>
                {flights[0].from}
                <ArrowForward
                  style={{
                    height: '16px',
                    display: 'inline',
                    position: 'relative',
                    top: '2px',
                  }}
                />
                {flights[flights.length - 1].to}
                <span style={{ marginLeft: '12px' }}>
                  {capitalize(
                    moment(flights[0].departure).format('ddd DD MMM')
                  )}
                </span>
              </Box>

              <Box>
                {stopText}
                <Box className={classes.dot} />
                {durationText}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3} mb={3}>
          <Divider />
        </Box>

        {renderFlights(flights)}

        {isOutbound && outboundTripRules?.length && (
          <>
            <Box mt={3} mb={3}>
              <Divider />
            </Box>

            <Typography className={classes.subTitle}>
              {t('rules and baggage')}
            </Typography>

            <Box className={classes.details}>
              {outboundTripRules.map((text) => (
                <Box>{text}</Box>
              ))}
            </Box>
          </>
        )}

        {!isOutbound && returnTripRules?.length && (
          <>
            <Box mt={3} mb={3}>
              <Divider />
            </Box>

            <Typography className={classes.subTitle}>
              {t('rules and baggage')}
            </Typography>

            <Box className={classes.details}>
              {returnTripRules.map((text) => (
                <Box>{text}</Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    )
  }

  const renderFlights = (flights) => {
    return flights.map((stop, i, all) => {
      const fKey = (
        <span style={{ marginLeft: '1.5ch' }}>
          ( {stop.airline} {stop.flightnumber} )
        </span>
      )
      const company = COMPANIES[stop.airline].label || null
      const underText = `${!!company ? company + ', ' : ''}${stop.airline} ${stop.flightnumber
        }${stop.departTerminal
          ? ', ' + t('terminal') + ' ' + stop.departTerminal
          : ''
        }`
      const nextRide = i < all.length - 1 && all[i + 1]
      const arrival = stop.arrival ? moment(stop.arrival) : null
      const nextDeparture = nextRide.departure
        ? moment(nextRide.departure)
        : null
      const stopTime =
        nextDeparture && arrival
          ? formatDuration(nextDeparture.diff(arrival, 'minutes'))
          : null
      const duration = formatDuration(stop.travelTime)

      const equipmentText = stop.equipmentType
        ? airplaneModels.find((plane) => plane.iata === stop.equipmentType)
          ?.model
        : null

      const checkinText = stop.checkinTime
        ? t('checkin time') + ': ' + stop.checkinTime
        : null

      const referenceText = stop.bookingRef
        ? t('booking reference') + ': ' + stop.bookingRef
        : null

      const bookingClassText = stop.bookingclass
        ? t('booking class') + ': ' + stop.bookingclass
        : null

      const passengerUids = model?.passengers?.map((p) => p.ind)
      const seatsText =
        stop.seats && Object.keys(stop.seats).length > 0
          ? Object.keys(stop.seats).filter((passengerUid) => passengerUids.includes(passengerUid)).map((passengerUid) => stop.seats[passengerUid].seatName).join(', ')
          : null

      return (
        <Box key={`itm-${itmKey++}`} mt={1} mb={1}>
          <Box>
            <span className={classes.weight}>
              <Moment format="HH:mm">{stop.departure}</Moment> &mdash;{' '}
              <Moment format="HH:mm">{stop.arrival}</Moment>
            </span>
            &nbsp; ({duration})
          </Box>
          <Box mt={1}>
            {stop.fromAirport} ({stop.from}){' '}
            <ArrowForward
              style={{
                height: '16px',
                display: 'inline',
                position: 'relative',
                top: '2px',
              }}
            />{' '}
            {stop.toAirport} ({stop.to})
          </Box>

          <Box className={classes.details} mt={3}>
            <Box>{underText}</Box>
            <Box>{equipmentText}</Box>
            <Box>{checkinText}</Box>
            <Box>{referenceText}</Box>
            <Box>{bookingClassText}</Box>

            {seatsText && (
              <Box>
                {t('seats')}: {seatsText}
              </Box>
            )}

            {COMPANIES[stop.airline]?.checkInUrl && (
              <Box mt={1}>
                <Button
                  disableElevation
                  target="_new"
                  variant="outlined"
                  href={COMPANIES[stop.airline].checkInUrl}
                  className={classes.smallBtn}
                >
                  {t('airline checkin')}
                </Button>
              </Box>
            )}

            {i < all.length - 1 && (
              <>
                <Box mt={2}>
                  <Divider />
                </Box>
                <Box mt={2} mb={2} style={{ color: 'black' }}>
                  <span className={classes.weight}>{stopTime}</span>
                  <span className={classes.dot} />
                  <span>
                    {t('change flight in', {
                      city: `${stop.toCity} (${stop.to})`,
                    })}
                  </span>
                  <Box className={classes.tag}>{t('stopover')}</Box>
                </Box>
                <Box mb={3}>
                  <Divider />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )
    })
  }

  const tripText = isTwoWay ? t('trip with return') : t('detail one way')
  const from = outboundFlights[0].fromCity
  const to = outboundFlights[outboundFlights.length - 1].toCity
  const destinationText = `${from} ${t('to').toLowerCase()} ${to}`
  const passengerText = (
    model.passengers.length > 1 ? t('travelers') : t('traveler')
  ).toLowerCase()

  const co2 = Math.floor(flights.reduce((sum, flight) => sum + flight.co2, 0))
  const distance = Math.floor(
    flights.reduce((sum, flight) => sum + flight.distance, 0)
  )

  return (
    <>
      <TripHeader type="flight" onClose={onClose} />
      <Box className={classes.titleSection}>
        <Typography className={classes.title}>{destinationText}</Typography>
        <Typography className={classes.subtitle}>
          {tripText}, {model.passengers.length} {passengerText}
        </Typography>
      </Box>

      {renderTrip(outboundFlights, true)}

      {returnFlights && renderTrip(returnFlights, false)}

      <Box className={classes.card}>
        <Typography className={classes.subTitle}>
          {t('other details')}
        </Typography>

        <Box className={classes.details}>
          {model.frequentFlyer && model.frequentFlyer.length > 0 && (
            <Box>
              {t('loyalty cards')}:{' '}
              {model.frequentFlyer.map((ff) => ff.membershipNumber).join(', ')}
            </Box>
          )}

          {model.airlineReferences?.length > 0 && (
            <Box>
              {t('booking reference')} {airlineReferences.join(', ')}
            </Box>
          )}
          <Box>
            {t('distance')} {distance} {t('km')}
          </Box>
          <Box>
            {t('emission')} {co2} {t('kilo')}
          </Box>

          {model.parsed ? (
            <Box>
              {t('payment')}: {t('paid')}
            </Box>
          ) : (
            <Box>
              {t('payment total total')}
              {isTwoWay ? ` (${t('detail mob short return')})` : ''}: {price}{' '}
              {currency || 'SEK'}
            </Box>
          )}
        </Box>
      </Box>

      <Box style={{ marginBottom: '100px' }} />

      {createPortal(
        <Box className={classes.footer}>
          <Divider mb={2} />

          {getCancelButton()}
        </Box>,
        mainRef.current
      )}
    </>
  )
}

export default memo(FlightTimeLine)
