import { useCallback, useState } from 'react'
import { getNonPastDate } from '../utils/formatDates'
import moment from 'moment'

const useBookingDates = (dep, arr, earliestDate, latestDate) => {
  const [checkIn, setCheckIn] = useState(!dep || moment(dep).isBefore(moment(), 'day') ? getNonPastDate(moment(), 1) : dep)
  const [checkOut, setCheckOut] = useState(
    moment(arr).isSameOrBefore(checkIn, 'day')
      ? moment(checkIn).clone().add(1, 'day')
      : arr
  )

  const depChange = useCallback(
    (date) => {
      setCheckIn(date)
      if (moment(date).isSameOrAfter(checkOut, 'day')) {
        const addOneDay = moment(new Date(date)).add(1, 'days')
        setCheckOut(addOneDay)
      }
    },
    [checkOut]
  )

  const arrOnChange = useCallback(
    (date) => {
      if (moment(date).isSame(new Date(), 'day')) {
        setCheckOut(moment(date).add(1, 'day'))
        setCheckIn(date)
      } else if (moment(date).isSameOrBefore(checkIn, 'day')) {
        setCheckOut(date)
        const subtractOneDay = moment(new Date(date)).subtract(1, 'days')
        setCheckIn(subtractOneDay)
      } else {
        setCheckOut(date)
      }
    },
    [checkIn]
  )

  return { checkIn, checkOut, depChange, arrOnChange }
}

export default useBookingDates
